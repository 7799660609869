import React, { Component } from 'react';
import { Tabs, Select, Input } from 'antd';
import { InfoCircleOutlined } from "@ant-design/icons";
import Checkbox from 'antd/lib/checkbox/Checkbox'; 
import Happy from "./../../../assets/images/happiness.png";
import TemplateTh from "./../../../assets/images/Temp1.svg";
import Modal from "react-responsive-modal";
import { SearchOutlined } from '@ant-design/icons';

class Template extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             UseTemp: false,
        }
    }
    handleUseTemplateOpen() {
        this.setState({UseTemp: true});
    }
    handleUseTemplateClose() {
        this.setState({UseTemp: false});
    }
    render() {
        const { TabPane } = Tabs;
        const { Option } = Select;
        return (
            <div className="TemplateMain">
                <div className="TabTemp">
                    <Tabs defaultActiveKey="1">
                            <TabPane key="1" tab={<label>Configure</label>}>
                                <div className="configure">
                                    <label className="creat">Create Email Template</label>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-12">
                                            <label className="desig">Name</label>
                                            <input type="text" placeholder="Enter Name" />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-12">
                                            <label className="desig">Subject Line<img src={Happy} alt="Happy" className="Smile" /></label>
                                            <Select defaultValue="Click to Select">
                                                <Option value="hod">Sub 1</Option>
                                                <Option value="asst">Sub 2</Option>
                                            </Select>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-4">
                                            <label className="desig">Sender Name</label>
                                            <input type="text" placeholder="Enter Sender Name" />
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <label className="desig">Sender Email Address</label>
                                            <input type="text" placeholder="Enter Sender Email" />
                                        </div>
                                        <div className="col-12 col-md-4 pl-0">
                                            <label className="desig1">Verify Email</label>
                                            <label className="desig2">Verify Email</label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-4">
                                            <label className="desig">CC</label>
                                            <input type="text" placeholder="CC" />
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <label className="desig">BCC</label>
                                            <input type="text" placeholder="BCC" />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-4">
                                            <label className="desig">Reply to Email</label>
                                            <input type="text" placeholder="Email" />
                                        </div>
                                        <div className="col-12 col-md-4">
                                            <label className="desig1">BCC</label>
                                            <label className="desig3">Reply Tracking <InfoCircleOutlined />
                                              <label class="switch">
                                                <input type="checkbox" />
                                                    <span class="slider round"></span>
                                                </label>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-12">
                                            <label className="design"><Checkbox>Same as Sender email address</Checkbox></label>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12 col-md-12">
                                            <label className="desig">Preview<img src={Happy} alt="Happy" className="Smile" /></label>
                                            <input type="text" placeholder="Preview" />
                                        </div>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane key="2" tab={<label>Style</label>}>
                                <div className="style">
                                    <Tabs defaultActiveKey="1">
                                        <TabPane key="1" tab={<label>Themes</label>}>
                                            <div className="Theme">
                                                <div className="row mb-3 sear">
                                                    <div className="col-12 col-md-3">
                                                        <Select defaultValue="All Template(50)">
                                                            <Option value="jack">Theme 1</Option>
                                                            <Option value="lucy">Theme 2</Option>
                                                        </Select>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        <Input placeholder="Search by title, type..." prefix={<SearchOutlined />} />
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-12 col-md-3">
                                                        <div className="card active">
                                                            <span className="im"></span>
                                                            {/* <img src={TemplateTh} alt="Template" /> */}
                                                            <label className="text">Template Name</label>
                                                            <div className="conthov">
                                                                <div>
                                                                    <button>Preview</button>
                                                                    <button type="button" onClick={this.handleUseTemplateOpen.bind(this)}>Use Template</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        <div className="card active">
                                                            <span className="im"></span>
                                                            {/* <img src={TemplateTh} alt="Template" /> */}
                                                            <label className="text">Template Name</label>
                                                            <div className="conthov">
                                                                <div>
                                                                    <button>Preview</button>
                                                                    <button>Use Template</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        <div className="card active">
                                                            <span className="im"></span>
                                                            {/* <img src={TemplateTh} alt="Template" /> */}
                                                            <label className="text">Template Name</label>
                                                            <div className="conthov">
                                                                <div>
                                                                    <button>Preview</button>
                                                                    <button>Use Template</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        <div className="card active">
                                                            <span className="im"></span>
                                                            {/* <img src={TemplateTh} alt="Template" /> */}
                                                            <label className="text">Template Name</label>
                                                            <div className="conthov">
                                                                <div>
                                                                    <button>Preview</button>
                                                                    <button>Use Template</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-3">
                                                        <div className="card active">
                                                            <span className="im"></span>
                                                            {/* <img src={TemplateTh} alt="Template" /> */}
                                                            <label className="text">Template Name</label>
                                                            <div className="conthov">
                                                                <div>
                                                                    <button>Preview</button>
                                                                    <button>Use Template</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        <div className="card active">
                                                            <span className="im"></span>
                                                            {/* <img src={TemplateTh} alt="Template" /> */}
                                                            <label className="text">Template Name</label>
                                                            <div className="conthov">
                                                                <div>
                                                                    <button>Preview</button>
                                                                    <button>Use Template</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        <div className="card active">
                                                            <span className="im"></span>
                                                            {/* <img src={TemplateTh} alt="Template" /> */}
                                                            <label className="text">Template Name</label>
                                                            <div className="conthov">
                                                                <div>
                                                                    <button>Preview</button>
                                                                    <button>Use Template</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-3">
                                                        <div className="card active">
                                                            <span className="im"></span>
                                                            {/* <img src={TemplateTh} alt="Template" /> */}
                                                            <label className="text">Template Name</label>
                                                            <div className="conthov">
                                                                <div>
                                                                    <button>Preview</button>
                                                                    <button>Use Template</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane key="2" tab={<label>Layouts</label>}>
                                            <div className="Theme">
                                                Layouts
                                            </div>
                                        </TabPane>
                                        <TabPane key="3" tab={<label>Saved</label>}>
                                            <div className="Theme">
                                                Saved
                                            </div>
                                        </TabPane>
                                        <TabPane key="4" tab={<label>Upload HTML</label>}>
                                            <div className="Theme">
                                                Upload HTML
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </TabPane>
                            <TabPane key="3" tab={<label>Design</label>}>
                                <div className="configure">
                                    Design
                                </div>
                            </TabPane>
                            <TabPane key="4" tab={<label>Review</label>}>
                                <div className="configure">
                                    Review
                                </div>
                            </TabPane>
                    </Tabs>
                    <Modal
                        open={this.state.UseTemp}
                        onClose={this.handleUseTemplateClose.bind(this)}
                        modalId="SelectTempModal"
                        overlayId="overlay"
                    >
                        <div className="createTempl">
                            <label>Change on the Template will cause a loss on your previous content.</label>
                            <div className="threebtn">
                                <button onClick={this.handleUseTemplateClose.bind(this)}>Cancel</button>
                                <button>Use Previous</button>
                                <button>Replace</button>
                            </div>
                        </div>
                    </Modal>
                    <div className="SaveExit">
                        <button>Save & Exit</button>
                        <button>Next</button>
                    </div>
                </div>
            </div>
        )
    }
}

export default Template
