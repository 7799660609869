import React, { Component } from "react";
import Add from "./../../../assets/images/add.svg";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import AddNote from "./../../../assets/images/AddNote.svg";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import { Table, Select, Checkbox, Slider } from "antd";
import Modal from "react-responsive-modal";
import FileUpload from "./../../../assets/images/upload.png";
import Dropzone from "react-dropzone";

class LeadScoring extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createLead: false,
    };
  }
  handleCreateLeadScoreOpen() {
    this.setState({ createLead: true });
  }
  handleCreateLeadScoreClose() {
    this.setState({ createLead: false });
  }
  start = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  onAfterChange = (value) => {
    console.log("onAfterChange: ", value);
  };
  onChange = (value) => {
    console.log("onChange: ", value);
  };
  render() {
    const columns = [
      {
        title: "Activity",
        dataIndex: "activity",
        key: "activity",
        render: (row, item) => {
          return (
            <div className="report">
              <label>New Ticket Creation</label>
            </div>
          );
        },
      },
      {
        title: "Display Name",
        dataIndex: "dname",
        key: "dname",
        render: (row, item) => {
          return (
            <div className="report">
              <label>Email Opened</label>
            </div>
          );
        },
      },
      {
        title: "Score",
        key: "score",
        dataIndex: "score",
        render: (row, item) => {
          return (
            <div className="report">
              <label>10</label>
            </div>
          );
        },
      },
      {
        title: "Tags",
        key: "tag",
        dataIndex: "tag",
        render: (row, item) => {
          return (
            <div className="report">
              <label>Email</label>
            </div>
          );
        },
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (row, item) => {
          return (
            <div className="Status">
              <Select defaultValue="Active">
                <Option value="Active">Active</Option>
                <Option value="Inactive">Inactive</Option>
              </Select>
            </div>
          );
        },
      },
      {
        title: "Actions",
        key: "actions",
        dataIndex: "actions",
        render: (row, item) => {
          return (
            <div className="Actionic">
              <img
                src={AddNote}
                alt="Edit"
                onClick={this.handleCreateLeadScoreOpen.bind(this)}
              />
              <img src={CloseCircle} alt="delete" />
            </div>
          );
        },
      },
    ];
    const data = [
      {
        key: "1",
      },
      {
        key: "2",
      },
      {
        key: "3",
      },
    ];
    const { Option } = Select;
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    return (
      <div className="LeadScorCusMain">
        <div className="Hier">
          <div className="Hierdf">
            <div className="hierleft">
              <h4>
                Lead Score Customization
                <img
                  src={Add}
                  alt="add"
                  onClick={this.handleCreateLeadScoreOpen.bind(this)}
                />
              </h4>
            </div>
          </div>
          <div className="LeadScorCusTable">
            <Table
              rowSelection={rowSelection}
              columns={columns}
              dataSource={data}
              pagination={false}
            />
          </div>
          {/* Create Hierarchy */}
          <Modal
            open={this.state.createLead}
            onClose={this.handleCreateLeadScoreClose.bind(this)}
            modalId="CreateLeadScoModal"
            overlayId="overlay"
          >
            <div className="createLeaScoCus">
              <div className="hier mb-3">
                <label>Lead Score Customization</label>
              </div>
              <div className="row mb-3">
                <div className="col-12 col-md-12">
                  <label className="desig">Categorize</label>
                  <label className="desig">
                    <Slider
                      range
                      step={10}
                      defaultValue={[30, 70]}
                      onChange={this.onChange}
                      onAfterChange={this.onAfterChange}
                    />
                  </label>
                  <div className="row mt-3">
                    <div className="col-12 col-md-4">
                      <div className="hott">
                        <label>
                          <span className="cold"></span>
                          <span className="desig">Cold</span>
                        </label>
                        <input placeholder="Cold" value="0-30" />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="hott">
                        <label>
                          <span className="warm"></span>
                          <span className="desig">Warm</span>
                        </label>
                        <input placeholder="Warm" value="31-70" />
                      </div>
                    </div>
                    <div className="col-12 col-md-4">
                      <div className="hott">
                        <label>
                          <span className="hot"></span>
                          <span className="design">Hot</span>
                        </label>
                        <input placeholder="Hot" value="71-100" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 col-md-12">
                  <label className="desig">Custom Notation Activities</label>
                  <div className="row mt-2">
                    <div className="col-12 col-md-3">
                      <label className="desig">Activitity Name</label>
                      <Select defaultValue="Email Opened">
                        <Option value="em1">Email Opened</Option>
                        <Option value="em2">Email Closed</Option>
                      </Select>
                    </div>
                    <div className="col-12 col-md-4">
                      <label className="desig">Subject Line</label>
                      <input
                        type="text"
                        placeholder="Welcome to Easyrewardz..."
                      />
                    </div>
                    <div className="col-12 col-md-3">
                      <label className="desig">Time Limit</label>
                      <Select defaultValue="Last 7 days">
                        <Option value="em1">Last 7 days</Option>
                        <Option value="em2">Last 6 days</Option>
                      </Select>
                    </div>
                    <div className="col-12 col-md-2">
                      <label className="desig">Score</label>
                      <Select defaultValue="10">
                        <Option value="em1">10</Option>
                        <Option value="em2">20</Option>
                      </Select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 col-md-12">
                  <label className="desig">Direction</label>
                  <Checkbox>Inbound</Checkbox>
                  <Checkbox>Outbound</Checkbox>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 col-md-3">
                  <button type="button" className="addStatusBtn">
                    Add Activity
                  </button>
                </div>
              </div>
            </div>
            <div className="editorbtn">
              <button
                type="button"
                onClick={this.handleCreateLeadScoreClose.bind(this)}
              >
                Cancel
              </button>
              <button type="button">ADD</button>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default LeadScoring;
