import React, { Component } from "react";
import LoginLogo from "./../../assets/images/logo.png";
import "./../../assets/css/Login/login.css";

class ProgramCode extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  handleProgramCodeSubmit = () => {
    this.props.history.push("login");
  };
  render() {
    return (
      <div>
        <div className="LoginBack">
          <div className="center" style={{position:"relative"}}>
            <div className="loginlogo">
              <img src={LoginLogo} alt="Logo" />
            </div>
            <div className="mb-3">
              <input type="text" className="email" placeholder="Program Code*" />
            </div>
            <div className="mb-3">
              <button type="button" className="loginbtn" onClick={this.handleProgramCodeSubmit.bind(this)}>Submit</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProgramCode;
