import React, { Component } from 'react'
import Add from "./../../../assets/images/add.svg";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import AddNote from "./../../../assets/images/AddNote.svg";
import { Input, Radio, Select, Table, Checkbox  } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Modal from "react-responsive-modal";
import FileUpload from "./../../../assets/images/upload.png";
import Dropzone from "react-dropzone";

class CrmRole extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            createCrmRole: false,
            Single: true,
            Multiple: false,
            FullAccess: false,
            RestAccess: true,
            LocaAccess: false,
            fileName: "",
            fileN: [],     
        }
    }
    handleCreateCrmRolesOpen() {
        this.setState({ createCrmRole: true });
    }
    handleCreateCrmRolesClose() {
        this.setState({ createCrmRole: false });
    }
    start = () => {
        this.setState({ loading: true });
        setTimeout(() => {
          this.setState({
            selectedRowKeys: [],
            loading: false,
          });
        }, 1000);
    };
    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };
    handleRedioButtonChange(check) {
        if (check === "Single") {
          this.setState({
            Single: true,
            Multiple: false,
          });
        } else {
          this.setState({
            Single: false,
            Multiple: true,
            fileName: "",
            fileN: [],
          });
        }
    }
    handleRedioButtonAccessChange(check) {
        if (check === "FullAccess") {
          this.setState({
            FullAccess: true,
            RestAccess: false,
            LocaAccess: false
          });
        } else if (check === "RestAccess") {
          this.setState({
            FullAccess: false,
            RestAccess: true,
            LocaAccess: false
          });
        }
        else {
        this.setState({
            FullAccess: false,
            RestAccess: false,
            LocaAccess: true
          });
        }
    }
    fileUpload = (e) => {
        var allFiles = [];
        var selectedFiles = e;
        if (selectedFiles) {
          allFiles.push(selectedFiles[0]);
    
          this.setState({
            fileN: allFiles,
            fileName: allFiles[0].name,
          });
        }
    };
    render() {
        const columns = [
            {
              title: 'Role Name',
              dataIndex: 'rName',
              key: 'rName',
              render: (row, item) => {
                return (
                  <div className="report">
                      <label>Jr. Design Engineer</label>
                  </div>
                );
              }
            },
            {
              title: 'User',
              dataIndex: 'user',
              key: 'user',
              render: (row, item) => {
                return (
                    <div className="report">
                        <label>Asst Manager</label>
                    </div>
                );
              }
            },
            {
              title: 'Created by',
              key: 'createdby',
              dataIndex: 'createdby',
              render: (row, item) => {
                return (
                  <div className="report">
                    <label>Vikash Sharma</label>
                  </div>
                );
              }
            },
            {
              title: 'Updated by',
              key: 'updatedby',
              dataIndex: 'updatedby',
              render: (row, item) => {
                return (
                  <div className="report">
                    <label>Vikash Sharma</label>
                  </div>
                );
              }
            },
            {
              title: 'Status',
              key: 'status',
              dataIndex: 'status',
              render: (row, item) => {
                return (
                    <div className="Status">
                        <Select defaultValue="Active">
                            <Option value="Active">Active</Option>
                            <Option value="Inactive">Inactive</Option>
                        </Select>
                    </div>
                );
              }
            },
            {
              title: 'Actions',
              key: 'actions',
              dataIndex: 'actions',
              render: (row, item) => {
                return (
                  <div className="Actionic">
                        <img src={AddNote} alt="Edit" onClick={this.handleCreateCrmRolesOpen.bind(this)} />
                        <img src={CloseCircle} alt="delete" />
                  </div>
                );
              }
            }
          ];
          const data = [
            {
              key: '1',
            },
            {
              key: '2',
            },
            {
              key: '3',
            }
          ];
        const { Option } = Select;
        const { loading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div className="CrmRoleMain">
                <div className="CrRoType">
                    <div className="Hierdf">
                        <div className="hierleft">
                            <h4>CRM Roles
                                <img src={Add} alt="add" onClick={this.handleCreateCrmRolesOpen.bind(this)} />
                            </h4>
                        </div>
                        <div className="hierright">
                            <Input placeholder="Search by title, type..." prefix={<SearchOutlined />} />
                        </div>
                    </div>
                    <div className="CrmRolesTable">
                        <Table 
                            rowSelection={rowSelection}
                            columns={columns} 
                            dataSource={data} 
                            pagination={false}
                        />
                    </div>
                    {/* Create CRM */}
                    <Modal
                        open={this.state.createCrmRole}
                        onClose={this.handleCreateCrmRolesClose.bind(this)}
                        modalId="CreateCrmRoleModal"
                        overlayId="overlay"
                    >
                        <div className="createCrmRole">
                            <div className="hier mb-3">
                                <label>Create CRM Role</label>
                            </div>
                            <div className="mb-2">
                                <Radio  type="radio" name="Single" checked={this.state.Single} onChange={this.handleRedioButtonChange.bind(this,"Single")}>Single</Radio>
                                <Radio type="radio" name="Single" checked={this.state.Multiple} onChange={this.handleRedioButtonChange.bind(this,"Multiple")}>Multiple</Radio>
                            </div>
                            {this.state.Single && (
                            <>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-12">
                                        <label className="desig">Role Name<span style={{color:"red"}}>*</span></label>
                                        <input type="text" placeholder="Like Manager" />
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-12">
                                        <label className="desig">Select a role to Clone<span style={{color:"red"}}>*</span></label>
                                        <Select defaultValue="Click to Select">
                                            <Option value="hod">HOD</Option>
                                            <Option value="asst">Asst Manager</Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-12">
                                        <label className="desig">Status</label>
                                        <Select defaultValue="Click to Select">
                                            <Option value="active">Active</Option>
                                            <Option value="inactive">Inactive</Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="row mb-5">
                                    <div className="col-12 col-md-12">
                                        <label className="desig mb-2">Default Scope</label>
                                        <Radio  type="radio" name="access" checked={this.state.FullAccess} onChange={this.handleRedioButtonAccessChange.bind(this,"FullAccess")}>Full Access</Radio>
                                        <Radio type="radio" name="access" checked={this.state.RestAccess} onChange={this.handleRedioButtonAccessChange.bind(this,"RestAccess")}>Restricted Access</Radio>
                                        <Radio type="radio" name="access" checked={this.state.LocaAccess} onChange={this.handleRedioButtonAccessChange.bind(this,"LocaAccess")}>Location Access</Radio>
                                        {/* <Radio>Location Access</Radio> */}
                                        {this.state.FullAccess && (
                                            <><label className="fullacces">This is useful for marketers, managers and admins who need complete access to data across the organization.</label></>
                                        )}
                                        {this.state.RestAccess && (
                                            <><label className="fullacces">This works best for vendors, contractors and partners who need to have restricted acces to your data.</label></>
                                        )}
                                        {this.state.LocaAccess && (
                                            <><label className="fullacces">Location Access</label></>
                                        )}
                                    </div>
                                </div>
                            </>
                            )}
                            {this.state.Multiple && (
                                <>
                                <div className="editor">
                                    <div className="mainfileUpload">
                                        <Dropzone onDrop={this.fileUpload.bind(this)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                            <input
                                                {...getInputProps()}
                                                className="file-upload d-none"
                                            />
                                            <div className="file-icon">
                                                <img src={FileUpload} alt="file-upload" />
                                            </div>
                                            <span className={"drop"}>Drop your file to upload</span>
                                            <button className="fileupload">Choose File</button>
                                            <span className="down">Download Sample File</span>
                                            </div>
                                        )}
                                        </Dropzone>
                                    </div>
                                </div>
                                </>
                            )}
                        </div>
                        <div className="editorbtn">
                                <button type="button" onClick={this.handleCreateCrmRolesClose.bind(this)}>Cancel</button>
                                <button type="button">CREATE</button>
                            </div>
                    </Modal>
                </div>               
            </div>
        )
    }
}

export default CrmRole
