import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

///--------------------css---------------------------------
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "react-responsive-modal/styles.css";
import "antd/dist/antd.css";

///--------------------js---------------------------------
import "./../node_modules/bootstrap/dist/js/bootstrap.js";
import "./../node_modules/bootstrap/dist/js/bootstrap.min.js";
import "./../node_modules/jquery/dist/jquery.js";
import "./../node_modules/popper.js/dist/popper.js";

ReactDOM.render(<App />, document.getElementById("root"));

