import React, { Component } from 'react';
import Add from "./../../../assets/images/add.svg";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import AddNote from "./../../../assets/images/AddNote.svg";
import { Input, Radio, Select, TimePicker, Table, DatePicker, Checkbox  } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import "./../../../assets/css/AdminSetting/adminSetting.css";
import Modal from "react-responsive-modal";
import moment from 'moment';
import FileUpload from "./../../../assets/images/upload.png";
import Dropzone from "react-dropzone";

class TaskType extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            createTask: false,
            Single: true,
            Multiple: false,
            fileName: "",
            fileN: [],
        }
    }
    handleCreateTaskOpen() {
        this.setState({ createTask: true });
    }
    handleCreateTaskClose() {
        this.setState({ createTask: false });
    }
    start = () => {
        this.setState({ loading: true });
        setTimeout(() => {
          this.setState({
            selectedRowKeys: [],
            loading: false,
          });
        }, 1000);
    };
    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };
    handleRedioButtonChange(check) {
        if (check === "Single") {
          this.setState({
            Single: true,
            Multiple: false,
          });
        } else {
          this.setState({
            Single: false,
            Multiple: true,
          });
        }
    }
    fileUpload = (e) => {
        var allFiles = [];
        var selectedFiles = e;
        if (selectedFiles) {
          allFiles.push(selectedFiles[0]);
    
          this.setState({
            fileN: allFiles,
            fileName: allFiles[0].name,
          });
        }
    };
    render() {
        const columns = [
            {
              title: 'Title',
              dataIndex: 'title',
              key: 'title',
              render: (row, item) => {
                return (
                  <div className="report">
                      <label>8750185777</label>
                  </div>
                );
              }
            },
            {
              title: 'Task Type',
              dataIndex: 'taskType',
              key: 'taskType',
              render: (row, item) => {
                return (
                    <div className="report">
                        <label>Test@123.com</label>
                    </div>
                );
              }
            },
            {
              title: 'Created by',
              key: 'createdby',
              dataIndex: 'createdby',
              render: (row, item) => {
                return (
                  <div className="report">
                    <label>Vikash Sharma</label>
                  </div>
                );
              }
            },
            {
              title: 'Updated by',
              key: 'updatedby',
              dataIndex: 'updatedby',
              render: (row, item) => {
                return (
                  <div className="report">
                    <label>Vikash Sharma</label>
                  </div>
                );
              }
            },
            {
              title: 'Status',
              key: 'status',
              dataIndex: 'status',
              render: (row, item) => {
                return (
                    <div className="Status">
                        <Select defaultValue="Active">
                            <Option value="Active">Active</Option>
                            <Option value="Inactive">Inactive</Option>
                        </Select>
                    </div>
                );
              }
            },
            {
              title: 'Actions',
              key: 'actions',
              dataIndex: 'actions',
              render: (row, item) => {
                return (
                  <div className="Actionic">
                        <img src={AddNote} alt="Edit" onClick={this.handleCreateTaskOpen.bind(this)} />
                        <img src={CloseCircle} alt="delete" />
                  </div>
                );
              }
            }
          ];
          const data = [
            {
              key: '1',
            },
            {
              key: '2',
            },
            {
              key: '3',
            }
          ];
        const { Option } = Select;
        const { loading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        const format = 'HH:mm';
        const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
        return (
            <div className="TaskTypeMain">
                <div className="TasType">
                    <div className="Hierdf">
                        <div className="hierleft">
                            <h4>Task Types
                                <img src={Add} alt="add" onClick={this.handleCreateTaskOpen.bind(this)} />
                            </h4>
                        </div>
                        <div className="hierright">
                            <Input placeholder="Search by title, type..." prefix={<SearchOutlined />} />
                        </div>
                    </div>
                    {/* <div className="hierdown">
                        <label><img src={CloseCircle} alt="Delete" />Delete</label>
                        <label><img src={Download} alt="Delete" />Excel</label>
                    </div> */}
                    <div className="TaskTypeTable">
                        <Table 
                            rowSelection={rowSelection}
                            columns={columns} 
                            dataSource={data} 
                            pagination={false}
                        />
                    </div>
                    {/* Create Task */}
                    <Modal
                        open={this.state.createTask}
                        onClose={this.handleCreateTaskClose.bind(this)}
                        modalId="CreateTaskTyModal"
                        overlayId="overlay"
                    >
                        <div className="createTas">
                            <div className="hier mb-3">
                                <label>Create Task Type</label>
                            </div>
                            <div className="mb-2">
                                <Radio  type="radio" name="Single" checked={this.state.Single} onChange={this.handleRedioButtonChange.bind(this,"Single")}>Single</Radio>
                                <Radio type="radio" name="Single" checked={this.state.Multiple} onChange={this.handleRedioButtonChange.bind(this,"Multiple")}>Multiple</Radio>
                            </div>
                            {this.state.Single && (
                            <>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-6">
                                        <label className="desig">Title<span style={{color:"red"}}>*</span></label>
                                        <input type="text" placeholder="Enter Title of Task" />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label className="desig">Task Type</label>
                                        <Select defaultValue="Click to Select">
                                            <Option value="hod">HOD</Option>
                                            <Option value="asst">Asst Manager</Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-12">
                                        <label className="desig">Description</label>
                                        <textarea row="5"></textarea>
                                        {/* <input type="text" placeholder="Description" /> */}
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-3 pr-0">
                                        <label className="desig">Due Date<span style={{color:"red"}}>*</span></label>
                                        <DatePicker defaultValue={moment('01/01/2015', dateFormatList[0])} format={dateFormatList} />
                                    </div>
                                    <div className="col-12 col-md-3">
                                        <label className="desig" style={{visibility:"hidden"}}>Due Date</label>
                                        <TimePicker defaultValue={moment('12:08', format)} format={format} />
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label className="desig">Outcome</label>
                                        <Select defaultValue="Click to Select">
                                            <Option value="hod">HOD</Option>
                                            <Option value="asst">Asst Manager</Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-6">
                                        <label className="desig">Related To</label>
                                        <Select defaultValue="Click to Select">
                                            <Option value="hod">HOD</Option>
                                            <Option value="asst">Asst Manager</Option>
                                        </Select>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <label className="desig">Collaborators</label>
                                        <Select defaultValue="Click to Select">
                                            <Option value="hod">HOD</Option>
                                            <Option value="asst">Asst Manager</Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-6">
                                        <label className="desig"><Checkbox>Mark as Completed</Checkbox></label>
                                    </div>
                                </div>
                            </>
                            )}
                            {this.state.Multiple && (
                                <div className="editor">
                                <div className="mainfileUpload">
                                    <Dropzone onDrop={this.fileUpload.bind(this)}>
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()}>
                                        <input
                                            {...getInputProps()}
                                            className="file-upload d-none"
                                        />
                                        <div className="file-icon">
                                            <img src={FileUpload} alt="file-upload" />
                                        </div>
                                        <span className={"drop"}>Drop your file to upload</span>
                                        <button className="fileupload">Choose File</button>
                                        <span className="down">Download Sample File</span>
                                        </div>
                                    )}
                                    </Dropzone>
                                </div>
                            </div>
                            )}
                        </div>
                        <div className="editorbtn">
                                <button type="button" onClick={this.handleCreateTaskClose.bind(this)}>Cancel</button>
                                <button type="button">CREATE</button>
                            </div>
                    </Modal>
                </div>               
            </div>
        )
    }
}

export default TaskType
