import React, { Component } from "react";
import Download from "./../../../assets/images/Download.svg";
import PrintImg from "./../../../assets/images/PrintImg.png";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import { SearchOutlined } from "@ant-design/icons";
import { Table, Input, Switch, Select } from "antd";

class RoleAccessControl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      Single: true,
      Multiple: false,
    };
  }

  render() {
    const { Option } = Select;
    return (
      <div className="roleaccessMain">
        <div className="Hier">
          <div className="Hierdf">
            <div className="hierleft">
              <h4>Role Access Control</h4>
            </div>
            <div className="hierright">
              <Input placeholder="Search by role name and service…" prefix={<SearchOutlined />} />
            </div>
          </div>
          <div className="hierdown1 mb-3">
            <div className="row">
              <div className="col-12 col-md-3">
                <Select defaultValue="Select Role">
                  <Option value="admin">Admin</Option>
                  <Option value="manager">Manager</Option>
                </Select>
              </div>
              <div className="col-12 col-md-3">
                <Select defaultValue="Select Service Name">
                  <Option value="call">Call</Option>
                  <Option value="feedback">Feedback</Option>
                </Select>
              </div>
              <div className="col-12 col-md-2">
                <img src={PrintImg} alt="PrintImg" className="printImg" />
              </div>
            </div>
          </div>
          <div className="hierdown">
            <label>
              <img src={Download} alt="Delete" />
              Excel
            </label>
          </div>
          <div className="RoleAccessTable">
            <Table
              columns={[
                {
                  title: "Services",
                  dataIndex: "services",
                  key: "services",
                  render: (row, item) => {
                    return (
                      <div className="report">
                        <label>
                          {item.services}
                          <span className="spanTbl">Custom</span>
                        </label>
                      </div>
                    );
                  },
                },
                {
                  title: "View",
                  dataIndex: "view",
                  key: "view",
                  render: (row, item) => {
                    return (
                      <div className="report">
                        <Switch checkedChildren="On" unCheckedChildren="Off" />
                      </div>
                    );
                  },
                },
                {
                  title: "Create",
                  key: "create",
                  dataIndex: "create",
                  render: (row, item) => {
                    return (
                      <div className="report">
                        <Switch checkedChildren="On" unCheckedChildren="Off" />
                      </div>
                    );
                  },
                },
                {
                  title: "Edit",
                  key: "edit",
                  dataIndex: "edit",
                  render: (row, item) => {
                    return (
                      <div className="report">
                        <Switch checkedChildren="On" unCheckedChildren="Off" />
                      </div>
                    );
                  },
                },
                {
                  title: "Delete",
                  key: "delete",
                  dataIndex: "delete",
                  render: (row, item) => {
                    return (
                      <div className="Status">
                        <Switch checkedChildren="On" unCheckedChildren="Off" />
                      </div>
                    );
                  },
                },
                {
                  title: "Download",
                  key: "download",
                  dataIndex: "download",
                  render: (row, item) => {
                    return (
                      <div className="Actionic">
                        <Switch checkedChildren="On" unCheckedChildren="Off" />
                      </div>
                    );
                  },
                },
              ]}
              dataSource={[
                {
                  key: "1",
                  services: "AssociateNetwork",
                },
                {
                  key: "2",
                  services: "Activity",
                },
                {
                  key: "3",
                  services: "Call",
                },
                {
                  key: "4",
                  services: "CallCampaign",
                },
                {
                  key: "5",
                  services: "CampaignSetting",
                },
                {
                  key: "6",
                  services: "Connect",
                },
                {
                  key: "7",
                  services: "EmailCampaign",
                },
              ]}
              pagination={false}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default RoleAccessControl;
