import React, { Component } from 'react'
import Add from "./../../../assets/images/add.svg";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import AddNote from "./../../../assets/images/AddNote.svg";
import { Input, Radio, Select, Table, Checkbox  } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Modal from "react-responsive-modal";

class SpecialityMaster extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             createSplMaster: false
        }
    }
    handleCreateSplMasterOpen() {
        this.setState({ createSplMaster: true });
    }
    handleCreateSplMasterClose() {
        this.setState({ createSplMaster: false });
    }
    start = () => {
        this.setState({ loading: true });
        setTimeout(() => {
          this.setState({
            selectedRowKeys: [],
            loading: false,
          });
        }, 1000);
    };
    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };
    render() {
        const columns = [
            {
              title: 'Speciality Master',
              dataIndex: 'splMaster',
              key: 'splMaster',
              render: (row, item) => {
                return (
                  <div className="report">
                      <label>Dermatologist</label>
                  </div>
                );
              }
            },
            {
              title: 'Speciality Code',
              dataIndex: 'splCode',
              key: 'splCode',
              render: (row, item) => {
                return (
                    <div className="report">
                        <label>DER</label>
                    </div>
                );
              }
            },
            {
              title: 'Created by',
              key: 'createdby',
              dataIndex: 'createdby',
              render: (row, item) => {
                return (
                  <div className="report">
                    <label>Vikash Sharma</label>
                  </div>
                );
              }
            },
            {
              title: 'Updated by',
              key: 'updatedby',
              dataIndex: 'updatedby',
              render: (row, item) => {
                return (
                  <div className="report">
                    <label>Vikash Sharma</label>
                  </div>
                );
              }
            },
            {
              title: 'Status',
              key: 'status',
              dataIndex: 'status',
              render: (row, item) => {
                return (
                    <div className="Status">
                        <Select defaultValue="Active">
                            <Option value="Active">Active</Option>
                            <Option value="Inactive">Inactive</Option>
                        </Select>
                    </div>
                );
              }
            },
            {
              title: 'Actions',
              key: 'actions',
              dataIndex: 'actions',
              render: (row, item) => {
                return (
                  <div className="Actionic">
                        <img src={AddNote} alt="Edit" onClick={this.handleCreateSplMasterOpen.bind(this)} />
                        <img src={CloseCircle} alt="delete" />
                        <img src={CloseCircle} alt="delete" />
                  </div>
                );
              }
            }
          ];
          const data = [
            {
              key: '1',
            },
            {
              key: '2',
            },
            {
              key: '3',
            }
          ];
        const { Option } = Select;
        const { loading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div className="SplMasterMain">
                <div className="SplMas">
                    <div className="Hierdf">
                        <div className="hierleft">
                            <h4>Speciality Master
                                <img src={Add} alt="add" onClick={this.handleCreateSplMasterOpen.bind(this)} />
                            </h4>
                        </div>
                        <div className="hierright">
                            <Input placeholder="Search by title, type..." prefix={<SearchOutlined />} />
                        </div>
                    </div>
                    <div className="SplMasterTable">
                        <Table 
                            rowSelection={rowSelection}
                            columns={columns} 
                            dataSource={data} 
                            pagination={false}
                        />
                    </div>
                    {/* Create Spl Master */}
                    <Modal
                        open={this.state.createSplMaster}
                        onClose={this.handleCreateSplMasterClose.bind(this)}
                        modalId="CreateSplMasterModal"
                        overlayId="overlay"
                    >
                        <div className="createSplMas">
                            <div className="hier mb-3">
                                <label>Create Speciality Master</label>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Speciality Master</label>
                                    <input type="text" placeholder="Enter Speciality Master" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Speciality Code</label>
                                    <input type="text" placeholder="Enter Speciality Code" />
                                </div>
                            </div>
                        </div>
                        <div className="editorbtn">
                                <button type="button" onClick={this.handleCreateSplMasterClose.bind(this)}>Cancel</button>
                                <button type="button">CREATE</button>
                            </div>
                    </Modal>
                </div>               
            </div>
        )
    }
}

export default SpecialityMaster
