import React, { Component } from "react";
import Header from "./Header";
import SideMenu from "./SideMenu";
import Aux from "./child";

export default class MasterLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHamburger: true,
    };
  }
  componentDidMount() {
  }

  handleCheckHambergaurClick=()=> {
    this.setState({
      isHamburger:!this.state.isHamburger
    })
  }

  render() {
    const { children } = this.props;
    return (
      <Aux>
        <div>
          <Header handleCheckHambergaurClick={this.handleCheckHambergaurClick}/>
        </div>
        <div className={this.state.isHamburger ? "leftside" : "leftside Hideleftside"}>
          <SideMenu />
        </div>
        <div
          className={this.state.isHamburger ? "main-content rightside" : "main-content rightside Hiderightside"}
          style={{ backgroundColor: "#F2F4F7" }}
        >
          {children}
        </div>
      </Aux>
    );
  }
}
