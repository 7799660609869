import React, { Component } from 'react';
import Add from "./../../../assets/images/add.svg";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import AddNote from "./../../../assets/images/AddNote.svg";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import { Input, Radio, Select, Table, Checkbox  } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Modal from "react-responsive-modal";
import FileUpload from "./../../../assets/images/upload.png";
import Dropzone from "react-dropzone";

class SlaMaster extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            createSlaMaster: false,
            Single: true,
            Multiple: false,
            fileName: "",
            fileN: [],
        }
    }
    handleCreateSlaMasterOpen() {
        this.setState({ createSlaMaster: true });
    }
    handleCreateSlaMasterClose() {
        this.setState({ createSlaMaster: false });
    }
    start = () => {
        this.setState({ loading: true });
        setTimeout(() => {
          this.setState({
            selectedRowKeys: [],
            loading: false,
          });
        }, 1000);
    };
    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };
    handleRedioButtonChange(check) {
        if (check === "Single") {
          this.setState({
            Single: true,
            Multiple: false,
          });
        } else {
          this.setState({
            Single: false,
            Multiple: true,
            fileName: "",
            fileN: [],
          });
        }
    }
    fileUpload = (e) => {
        var allFiles = [];
        var selectedFiles = e;
        if (selectedFiles) {
          allFiles.push(selectedFiles[0]);
    
          this.setState({
            fileN: allFiles,
            fileName: allFiles[0].name,
          });
        }
    };
    render() {
        const columns = [
            {
              title: 'Issue Type',
              dataIndex: 'issueType',
              key: 'issueType',
              render: (row, item) => {
                return (
                  <div className="report">
                      <label>Voltas Issue 1</label>
                  </div>
                );
              }
            },
            {
                title: 'Sla By Priority',
                dataIndex: 'slaPri',
                key: 'slaPri',
                render: (row, item) => {
                  return (
                    <div className="report">
                        <label>Urgent, Low, High, Medium</label>
                    </div>
                  );
                }
              },
            {
              title: 'Created by',
              key: 'createdby',
              dataIndex: 'createdby',
              render: (row, item) => {
                return (
                  <div className="report">
                    <label>Vikash Sharma</label>
                  </div>
                );
              }
            },
            {
              title: 'Updated by',
              key: 'updatedby',
              dataIndex: 'updatedby',
              render: (row, item) => {
                return (
                  <div className="report">
                    <label>Vikash Sharma</label>
                  </div>
                );
              }
            },
            {
              title: 'Status',
              key: 'status',
              dataIndex: 'status',
              render: (row, item) => {
                return (
                    <div className="Status">
                        <Select defaultValue="Active">
                            <Option value="Active">Active</Option>
                            <Option value="Inactive">Inactive</Option>
                        </Select>
                    </div>
                );
              }
            },
            {
              title: 'Actions',
              key: 'actions',
              dataIndex: 'actions',
              render: (row, item) => {
                return (
                  <div className="Actionic">
                        <img src={AddNote} alt="Edit" onClick={this.handleCreateSlaMasterOpen.bind(this)} />
                        <img src={CloseCircle} alt="delete" />
                  </div>
                );
              }
            }
          ];
          const data = [
            {
              key: '1',
            },
            {
              key: '2',
            },
            {
              key: '3',
            }
          ];
        const { Option } = Select;
        const { loading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div className="SlaMasterMain">
                <div className="TasType">
                    <div className="Hierdf">
                        <div className="hierleft">
                            <h4>SLA Master
                                <img src={Add} alt="add" onClick={this.handleCreateSlaMasterOpen.bind(this)} />
                            </h4>
                        </div>
                        <div className="hierright">
                            <Input placeholder="Search by Name..." prefix={<SearchOutlined />} />
                        </div>
                    </div>
                    <div className="SlaMasterTable">
                        <Table 
                            rowSelection={rowSelection}
                            columns={columns} 
                            dataSource={data} 
                            pagination={false}
                        />
                    </div>
                    {/* Create Sla Master */}
                    <Modal
                        open={this.state.createSlaMaster}
                        onClose={this.handleCreateSlaMasterClose.bind(this)}
                        modalId="CreateSlaMasterModal"
                        overlayId="overlay"
                    >
                        <div className="createSlaMa">
                            <div className="hier mb-3">
                                <label>Create SLA</label>
                                <label className="dndChe"><Checkbox>Consider DND Timeing</Checkbox></label>
                            </div>
                            <div className="mb-2">
                                <Radio  type="radio" name="Single" checked={this.state.Single} onChange={this.handleRedioButtonChange.bind(this,"Single")}>Single</Radio>
                                <Radio type="radio" name="Single" checked={this.state.Multiple} onChange={this.handleRedioButtonChange.bind(this,"Multiple")}>Multiple</Radio>
                            </div>
                            {this.state.Single && (
                                <>
                                <div className="row mb-2">
                                    <div className="col-12 col-md-12">
                                        <label className="desig">Issue Type<span style={{color:"red"}}>*</span></label>
                                        <Select defaultValue="Click to Select">
                                            <Option value="Active">Type 1</Option>
                                            <Option value="Inactive">Type 2</Option>
                                        </Select>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-12">
                                        <div className="card">
                                            <div className="row">
                                                <div className="col-12 col-md-3">
                                                    <label className="pro">Priority</label>
                                                    <label className="low">Low</label>
                                                    <label className="low">Medium / Normal</label>
                                                    <label className="low">High</label>
                                                    <label className="low">Urgent</label>
                                                </div>
                                                <div className="col-12 col-md-3">
                                                    <label className="pro">%SLA Breach</label>
                                                    <label className="high">
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                        <input className="" value="%" />
                                                    </label>
                                                    <label className="high">
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                        <input className="" value="%" />
                                                    </label>
                                                    <label className="high">
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                        <input className="" value="%" />
                                                    </label>
                                                    <label className="high">
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                        <input className="" value="%" />
                                                    </label>
                                                </div>
                                                <div className="col-12 col-md-3 pl-0">
                                                    <label className="pro">SLA Response Time</label>
                                                    <label className="high">
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                    </label>
                                                    <label className="high">
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                    </label>
                                                    <label className="high">
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                    </label>
                                                    <label className="high">
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                    </label>
                                                </div>
                                                <div className="col-12 col-md-3 pl-0">
                                                    <label className="pro">SLA Resolution Time</label>
                                                    <label className="high">
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                    </label>
                                                    <label className="high">
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                    </label>
                                                    <label className="high">
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                    </label>
                                                    <label className="high">
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                        <Select defaultValue="00">
                                                            <Option value="Active">00</Option>
                                                            <Option value="Inactive">01</Option>
                                                        </Select>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12 col-md-12">
                                        <label className="desig">Status</label>
                                        <Select defaultValue="Click to Select">
                                            <Option value="Active">Active</Option>
                                            <Option value="Inactive">Inactive</Option>
                                        </Select>
                                    </div>
                                </div>
                                </>
                            )}
                            {this.state.Multiple && (
                                <>
                                <div className="editor">
                                    <div className="mainfileUpload">
                                        <Dropzone onDrop={this.fileUpload.bind(this)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                            <input
                                                {...getInputProps()}
                                                className="file-upload d-none"
                                            />
                                            <div className="file-icon">
                                                <img src={FileUpload} alt="file-upload" />
                                            </div>
                                            <span className={"drop"}>Drop your file to upload</span>
                                            <button className="fileupload">Choose File</button>
                                            <span className="down">Download Sample File</span>
                                            </div>
                                        )}
                                        </Dropzone>
                                    </div>
                                </div>
                                </>
                            )}
                        </div>
                        <div className="editorbtn">
                                <button type="button" onClick={this.handleCreateSlaMasterClose.bind(this)}>Cancel</button>
                                <button type="button">CREATE</button>
                            </div>
                    </Modal>
                </div>               
            </div>
        )
    }
}

export default SlaMaster
