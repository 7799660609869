import React, { Component } from 'react'
import Add from "./../../../assets/images/add.svg";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import AddNote from "./../../../assets/images/AddNote.svg";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import { Input, Radio, Select, Table, Checkbox  } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Modal from "react-responsive-modal";

class Priority extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            createPriority: false,
        }
    }
    handleCreatePriorityOpen() {
        this.setState({ createPriority: true });
    }
    handleCreatePriorityClose() {
        this.setState({ createPriority: false });
    }
    start = () => {
        this.setState({ loading: true });
        setTimeout(() => {
          this.setState({
            selectedRowKeys: [],
            loading: false,
          });
        }, 1000);
    };
    onSelectChange = selectedRowKeys => {
        this.setState({ selectedRowKeys });
    };
    render() {
        const columns = [
            {
              title: 'Priority',
              dataIndex: 'priority',
              key: 'priority',
              render: (row, item) => {
                return (
                  <div className="report">
                      <label>Low</label>
                  </div>
                );
              }
            },
            {
              title: 'Created by',
              key: 'createdby',
              dataIndex: 'createdby',
              render: (row, item) => {
                return (
                  <div className="report">
                    <label>Vikash Sharma</label>
                  </div>
                );
              }
            },
            {
              title: 'Updated by',
              key: 'updatedby',
              dataIndex: 'updatedby',
              render: (row, item) => {
                return (
                  <div className="report">
                    <label>Vikash Sharma</label>
                  </div>
                );
              }
            },
            {
              title: 'Created Date',
              key: 'createdate',
              dataIndex: 'createdate',
              render: (row, item) => {
                return (
                  <div className="report">
                    <label>12th September 2020</label>
                  </div>
                );
              }
            },
            {
              title: 'Status',
              key: 'status',
              dataIndex: 'status',
              render: (row, item) => {
                return (
                    <div className="Status">
                        <Select defaultValue="Active">
                            <Option value="Active">Active</Option>
                            <Option value="Inactive">Inactive</Option>
                        </Select>
                    </div>
                );
              }
            },
            {
              title: 'Actions',
              key: 'actions',
              dataIndex: 'actions',
              render: (row, item) => {
                return (
                  <div className="Actionic">
                        <img src={AddNote} alt="Edit" onClick={this.handleCreatePriorityOpen.bind(this)} />
                        <img src={CloseCircle} alt="delete" />
                  </div>
                );
              }
            }
          ];
          const data = [
            {
              key: '1',
            },
            {
              key: '2',
            },
            {
              key: '3',
            }
          ];
        const { Option } = Select;
        const { loading, selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div className="PriorityMain">
                <div className="TasType">
                    <div className="Hierdf">
                        <div className="hierleft">
                            <h4>Priority
                                <img src={Add} alt="add" onClick={this.handleCreatePriorityOpen.bind(this)} />
                            </h4>
                        </div>
                        <div className="hierright">
                            <Input placeholder="Search by Name..." prefix={<SearchOutlined />} />
                        </div>
                    </div>
                    <div className="PriorityTable">
                        <Table 
                            rowSelection={rowSelection}
                            columns={columns} 
                            dataSource={data} 
                            pagination={false}
                        />
                    </div>
                    {/* Create Priority */}
                    <Modal
                        open={this.state.createPriority}
                        onClose={this.handleCreatePriorityClose.bind(this)}
                        modalId="CreatePriorityModal"
                        overlayId="overlay"
                    >
                        <div className="createPrior">
                            <div className="hier mb-3">
                                <label>Create Priority</label>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Priority Name</label>
                                    <input type="text" placeholder="Enter Priority Name" />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-12 col-md-12">
                                    <label className="desig">Status</label>
                                    <Select defaultValue="Click to Select">
                                        <Option value="Active">Active</Option>
                                        <Option value="Inactive">Inactive</Option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="editorbtn">
                                <button type="button" onClick={this.handleCreatePriorityClose.bind(this)}>Cancel</button>
                                <button type="button">CREATE</button>
                            </div>
                    </Modal>
                </div>               
            </div>
        )
    }
}

export default Priority
