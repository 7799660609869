import React, { Component } from "react";
import Communication from "./../../../assets/images/communication.svg";
import Report from "./../../../assets/images/Report.svg";
import Workflow from "./../../../assets/images/workflow.svg";
import Assets from "./../../../assets/images/assets.svg";
import Order from "./../../../assets/images/Order.svg";
import Campaign from "./../../../assets/images/campaign.svg";
import Opportunity from "./../../../assets/images/opportunity.svg";
import Lead from "./../../../assets/images/Lead.svg";
import Dashboard from "./../../../assets/images/Dashboard.svg";
import { Link } from "react-router-dom";
import Setting from "./../../../assets/images/Setting.png";
import { Menu } from "antd";
class SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkLink: "LeadList",
    };
  }

  componentDidMount() {}

  handleCheckPageDirect(link) {
    this.setState({
      checkLink: link,
    });
  }
  render() {
    const { SubMenu } = Menu;
    return (
      <div>
        <div className="sidemenu">
          <ul>
            <li>
              <Link>
                <img src={Dashboard} alt="Home" />
                <label>Dashboard</label>
              </Link>
            </li>
            <li
              className={this.state.checkLink === "LeadList" ? "active" : ""}
              onClick={this.handleCheckPageDirect.bind(this, "LeadList")}
            >
              <Link to="LeadList">
                <img src={Lead} alt="Home" />
                <label>Leads</label>
              </Link>
            </li>
            <li
              className={this.state.checkLink === "Opportunity" ? "active" : ""}
              onClick={this.handleCheckPageDirect.bind(this, "Opportunity")}
            >
              <Link to="Opportunity">
                <img src={Opportunity} alt="Opportunity" />
                <label>Opportunity</label>
              </Link>
            </li>
            <li
              className={
                this.state.checkLink === "adminsetting" ? "p-0 active" : "p-0"
              }
              onClick={this.handleCheckPageDirect.bind(this, "adminsetting")}
            >
              <Menu mode="inline">
                <SubMenu
                  key="sub1"
                  title={
                    <div>
                      <img src={Setting} alt="Home" />
                      <label>Admin Setting</label>
                    </div>
                  }
                >
                  <Menu.Item key="1">
                    <Link to="hierarchy">
                      <img src={Communication} alt="Setting" />
                      <label className="HierSubMenu">Hierarchy</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="2">
                    <Link to="Location">
                      <img src={Communication} alt="Setting" />
                      <label className="HierSubMenu">Location</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="3">
                    <Link to="TaskType">
                      <img src={Communication} alt="Setting" />
                      <label className="HierSubMenu">Task Types</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="4">
                    <Link to="Template">
                      <img src={Communication} alt="Setting" />
                      <label className="HierSubMenu">Templates</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="5">
                    <Link to="Alerts">
                      <img src={Communication} alt="Setting" />
                      <label className="HierSubMenu">Alerts</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="6">
                    <Link>
                      <img src={Communication} alt="Setting" />
                      <label className="HierSubMenu">Lead Source</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="7">
                    <Link to="Category">
                      <img src={Communication} alt="Setting" />
                      <label className="HierSubMenu">Category</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="8">
                    <Link to="Users">
                      <img src={Communication} alt="Setting" />
                      <label className="HierSubMenu">Users</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="9">
                    <Link to="CrmRole">
                      <img src={Communication} alt="Setting" />
                      <label className="HierSubMenu">CRM Roles</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="10">
                    <Link to="SpecialityMaster">
                      <img src={Communication} alt="Setting" />
                      <label className="HierSubMenu">Speciality Master</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="11">
                    <Link to="Priority">
                      <img src={Communication} alt="Setting" />
                      <label className="HierSubMenu">Priority</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="12">
                    <Link to="SlaMaster">
                      <img src={Communication} alt="Setting" />
                      <label className="HierSubMenu">SLA Master</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="13">
                    <Link to="Notification">
                      <img src={Communication} alt="Setting" />
                      <label className="HierSubMenu">Notifications</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="14">
                    <Link to="LeadScoring">
                      <img src={Communication} alt="Setting" /><label className="HierSubMenu">Lead Scoring</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="15">
                    <Link>
                      <img src={Communication} alt="Setting" />
                      <label className="HierSubMenu">Journey Builder</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="16">
                    <Link to="Dnd">
                      <img src={Communication} alt="Setting" />
                      <label className="HierSubMenu">DND (Calls/Email)</label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="17">
                    <Link to="leadStageManage">
                      <img src={Communication} alt="Setting" />
                      <label className="HierSubMenu">
                        Lead Stage Management
                      </label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="18">
                    <Link to="CustomFields">
                      <img src={Communication} alt="Setting" />
                      <label className="HierSubMenu">
                        Manage Custom Fields
                      </label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="19">
                    <Link>
                      <img src={Communication} alt="Setting" />
                      <label className="HierSubMenu">
                        Landing Pages Templates
                      </label>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="20">
                    <Link to="RoleAccessControl">
                      <img src={Communication} alt="Setting" />
                      <label className="HierSubMenu">
                      Role Access Control
                      </label>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              </Menu>
            </li>
            <li>
              <Link>
                <img src={Campaign} alt="Home" />
                <label>Campaigns</label>
              </Link>
            </li>
            <li>
              <Link>
                <img src={Order} alt="Home" />
                <label>Orders</label>
              </Link>
            </li>
            <li>
              <Link>
                <img src={Assets} alt="Home" />
                <label>Assets</label>
              </Link>
            </li>
            <li>
              <Link>
                <img src={Workflow} alt="Home" />
                <label>Workflow</label>
              </Link>
            </li>
            <li>
              <Link>
                <img src={Report} alt="Home" />
                <label>Reports</label>
              </Link>
            </li>
            <li>
              <Link>
                <img src={Communication} alt="Home" />
                <label>Communication</label>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default SideMenu;
