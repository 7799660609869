import React, { Component } from "react";
import Add from "./../../../assets/images/add.svg";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import moment from "moment";
import AddNote from "./../../../assets/images/AddNote.svg";
import { Input, Radio, Select, Table, Steps, Popover, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import Modal from "react-responsive-modal";
import FileUpload from "./../../../assets/images/upload.png";
import Dropzone from "react-dropzone";

const { Step } = Steps;
class Users extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createUser: false,
      Single: true,
      Multiple: false,
      CurrentStep: 0,
      fileName: "",
      fileN: [],
    };
  }
  handleCreateUserOpen() {
    this.setState({ createUser: true });
  }
  handleCreateUserClose() {
    this.setState({ createUser: false, CurrentStep: 0 });
  }
  start = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  handleRedioButtonChange(check) {
    if (check === "Single") {
      this.setState({
        Single: true,
        Multiple: false,
      });
    } else {
      this.setState({
        Single: false,
        Multiple: true,
      });
    }
  }
  handleChangeStep = (CurrentStep) => {
    this.setState({
      CurrentStep,
    });
  };
  handleNextStepClick() {
    if (this.state.CurrentStep === 0) {
      this.setState({
        CurrentStep: 1,
      });
    } else if (this.state.CurrentStep === 1) {
      this.setState({
        CurrentStep: 2,
      });
    } else {
      this.setState({
        CurrentStep: 3,
      });
    }
  }
  fileUpload = (e) => {
    var allFiles = [];
    var selectedFiles = e;
    if (selectedFiles) {
      allFiles.push(selectedFiles[0]);

      this.setState({
        fileN: allFiles,
        fileName: allFiles[0].name,
      });
    }
  };
  render() {
    const data = [
      {
        key: "1",
        userName: (
          <div className="report">
            <label>Vijay</label>
          </div>
        ),
        mobileNo: (
          <div className="report">
            <label>8750185777</label>
          </div>
        ),
        emailAddress: (
          <div className="report">
            <label>Test@123.com</label>
          </div>
        ),
        designation: (
          <div className="report">
            <label>Manager</label>
          </div>
        ),
      },
      {
        key: "2",
        userName: (
          <div className="report">
            <label>Vijay</label>
          </div>
        ),
        mobileNo: (
          <div className="report">
            <label>8750185777</label>
          </div>
        ),
        emailAddress: (
          <div className="report">
            <label>Test@123.com</label>
          </div>
        ),
        designation: (
          <div className="report">
            <label>Manager</label>
          </div>
        ),
      },
      {
        key: "3",
        userName: (
          <div className="report">
            <label>Vijay</label>
          </div>
        ),
        mobileNo: (
          <div className="report">
            <label>8750185777</label>
          </div>
        ),
        emailAddress: (
          <div className="report">
            <label>Test@123.com</label>
          </div>
        ),
        designation: (
          <div className="report">
            <label>Manager</label>
          </div>
        ),
      },
    ];
    const { Option } = Select;
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
    const customDot = (dot, { status, index }) => (
      <Popover
        content={
          <span>
            step {index} status: {status}
          </span>
        }
      >
        {dot}
      </Popover>
    );
    return (
      <div className="UsersMain">
        <div className="TasType">
          <div className="Hierdf">
            <div className="hierleft">
              <h4>
                Users
                <img
                  src={Add}
                  alt="add"
                  onClick={this.handleCreateUserOpen.bind(this)}
                />
              </h4>
            </div>
            <div className="hierright">
              <Input
                placeholder="Search by designation, user name..."
                prefix={<SearchOutlined />}
              />
            </div>
          </div>

          <div className="UsersTable">
            <Table
              rowSelection={rowSelection}
              columns={[
                {
                  title: "User Name",
                  dataIndex: "userName",
                  key: "userName",
                  width: 150,
                },
                {
                  title: "Mobile No.",
                  dataIndex: "mobileNo",
                  key: "mobileNo",
                },
                {
                  title: "Email Address",
                  key: "emailAddress",
                  dataIndex: "emailAddress",
                },
                {
                  title: "Designation",
                  key: "designation",
                  dataIndex: "designation",
                },
                {
                  title: "Status",
                  key: "status",
                  dataIndex: "status",
                  render: (row, item) => {
                    return (
                      <div className="Status">
                        <Select defaultValue="Active">
                          <Option value="Active">Active</Option>
                          <Option value="Inactive">Inactive</Option>
                        </Select>
                      </div>
                    );
                  },
                },
                {
                  title: "Actions",
                  key: "actions",
                  dataIndex: "actions",
                  width: 100,
                  render: (row, item) => {
                    return (
                      <div className="Actionic">
                        <img
                          src={AddNote}
                          alt="Edit"
                          onClick={this.handleCreateUserOpen.bind(this)}
                        />
                        <img src={CloseCircle} alt="delete" />
                      </div>
                    );
                  },
                },
              ]}
              dataSource={data}
              pagination={false}
            />
          </div>
          {/* Create Users */}
          <Modal
            open={this.state.createUser}
            onClose={this.handleCreateUserClose.bind(this)}
            modalId="CreateUserModal"
            overlayId="overlay"
          >
            <div>
              <div className="createUser">
                <div className="Steep">
                  <Steps
                    current={this.state.CurrentStep}
                    progressDot={customDot}
                    onChange={this.handleChangeStep}
                  >
                    <Step title="Basic Info" />
                    <Step title="Educational Info" />
                    <Step title="Linked Centres" />
                    <Step title="Leads Despositions" />
                  </Steps>
                </div>
                <div className="hier mb-3 mt-4">
                  <label>Create User</label>
                </div>
                <div className="mb-2">
                  <Radio
                    type="radio"
                    name="Single"
                    checked={this.state.Single}
                    onChange={this.handleRedioButtonChange.bind(this, "Single")}
                  >
                    Single
                  </Radio>
                  <Radio
                    type="radio"
                    name="Single"
                    checked={this.state.Multiple}
                    onChange={this.handleRedioButtonChange.bind(
                      this,
                      "Multiple"
                    )}
                  >
                    Multiple
                  </Radio>
                </div>
                {this.state.Single && (
                  <>
                    {this.state.CurrentStep === 0 && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">
                              Email<span style={{ color: "red" }}>*</span>
                            </label>
                            <input type="text" placeholder="Enter Email" />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">
                              Full Name<span style={{ color: "red" }}>*</span>
                            </label>
                            <input type="text" placeholder="Enter Full Name" />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">User Name</label>
                            <input type="text" placeholder="Enter User Name" />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">Mobile Number</label>
                            <input
                              type="text"
                              placeholder="Enter Mobile Number"
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Primary Centre</label>
                            <Select defaultValue="Click to Select">
                              <Option value="test">test</Option>
                            </Select>
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">User ID</label>
                            <input type="text" placeholder="Enter User ID" />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Date of Birth</label>
                            <DatePicker
                              defaultValue={moment(
                                "01/01/2015",
                                dateFormatList[0]
                              )}
                              format={dateFormatList}
                              style={{ display: "block" }}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">Age</label>
                            <input type="text" placeholder="Enter Age" />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Langauges Known</label>
                            <Select defaultValue="Click to Select">
                              <Option value="english">English</Option>
                            </Select>
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">Gender</label>
                            <Select defaultValue="Click to Select">
                              <Option value="male">Male</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Speciality</label>
                            <Select defaultValue="Click to Select">
                              <Option value="test">Test</Option>
                            </Select>
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">Skill Grade</label>
                            <Select defaultValue="Click to Select">
                              <Option value="first">First</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Registration Number</label>
                            <Select defaultValue="Click to Select">
                              <Option value="123">123</Option>
                            </Select>
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">
                              Registration Country
                            </label>
                            <Select defaultValue="Click to Select">
                              <Option value="india">India</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Registration State</label>
                            <Select defaultValue="Click to Select">
                              <Option value="gujarat">Gujarat</Option>
                            </Select>
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">
                              Work Experience (years)
                            </label>
                            <Select defaultValue="Click to Select">
                              <Option value="1">1</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Lead Access Type</label>
                            <Select defaultValue="Click to Select">
                              <Option value="test">test</Option>
                            </Select>
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">Designation</label>
                            <Select defaultValue="Click to Select">
                              <Option value="manager">Manager</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Reporting Manager</label>
                            <Select defaultValue="Click to Select">
                              <Option value="test">test</Option>
                            </Select>
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">CRM Role</label>
                            <Select defaultValue="Click to Select">
                              <Option value="manager">Manager</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">User Level</label>
                            <Select defaultValue="Click to Select">
                              <Option value="test">test</Option>
                            </Select>
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">User Zone</label>
                            <Select defaultValue="Click to Select">
                              <Option value="manager">East</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">User Region</label>
                            <Select defaultValue="Click to Select">
                              <Option value="east">East</Option>
                            </Select>
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">User City</label>
                            <Select defaultValue="Click to Select">
                              <Option value="ahmedabad">Ahmedabad</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">User Centre</label>
                            <Select defaultValue="Click to Select">
                              <Option value="east">East</Option>
                            </Select>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.CurrentStep === 1 && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Degree Name</label>
                            <Select defaultValue="Click to Select">
                              <Option value="guj">Test</Option>
                            </Select>
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">Institute Name</label>
                            <Select defaultValue="Click to Select">
                              <Option value="ahm">Test</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Clinical Skills</label>
                            <input
                              type="text"
                              placeholder="Enter Clinical Skills"
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">Certifications</label>
                            <Select defaultValue="Click to Select">
                              <Option value="ahm">Test</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Membership</label>
                            <input type="text" placeholder="Enter Membership" />
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">Publications</label>
                            <input
                              type="text"
                              placeholder="Enter Publications"
                            />
                          </div>
                        </div>

                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">
                              Awards &amp; Recognitions
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Awards & Recognitions"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.CurrentStep === 2 && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">Associated Centre</label>
                            <Select defaultValue="Click to Select">
                              <Option value="ahm">Test</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">Associated Centre </label>
                            <Select defaultValue="Click to Select">
                              <Option value="ahm">Test</Option>
                            </Select>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.CurrentStep === 3 && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">Leads Despositions</label>
                            <Select defaultValue="Click to Select">
                              <Option value="ahm">Test</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">Leads Sub Despositions</label>
                            <Select defaultValue="Click to Select">
                              <Option value="ahm">Test</Option>
                            </Select>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {this.state.Multiple && (
                  <>
                    <div className="editor">
                      <div className="mainfileUpload">
                        <Dropzone onDrop={this.fileUpload.bind(this)}>
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <input
                                {...getInputProps()}
                                className="file-upload d-none"
                              />
                              <div className="file-icon">
                                <img src={FileUpload} alt="file-upload" />
                              </div>
                              <span className={"drop"}>
                                Drop your file to upload
                              </span>
                              <button className="fileupload">
                                Choose File
                              </button>
                              <span className="down">Download Sample File</span>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="editorbtn">
                <button
                  type="button"
                  onClick={this.handleCreateUserClose.bind(this)}
                >
                  Cancel
                </button>
                {this.state.CurrentStep === 3 ? (
                  <button type="button">Add</button>
                ) : (
                  <button
                    type="button"
                    onClick={this.handleNextStepClick.bind(this)}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Users;
