import React, { Component } from "react";
import "./../../../assets/css/Leads/leadDetail.css";
import Back from "./../../../assets/images/CircleBack.svg";
import User from "./../../../assets/images/Userr.png";
import Meet from "./../../../assets/images/Meet.svg";
import Call from "./../../../assets/images/Call.svg";
import Email from "./../../../assets/images/email.svg";
import Notes from "./../../../assets/images/Notes.svg";
import Task from "./../../../assets/images/Task.svg";
import History from "./../../../assets/images/HistoryBlue.svg";
import Doller from "./../../../assets/images/Doller.svg";
import NotesSolid from "./../../../assets/images/Petrol.svg";
import SmS from "./../../../assets/images/Sms.png";
import { Tabs, Select, DatePicker, TimePicker, Collapse } from 'antd';
import CKEditor from "ckeditor4-react";
import moment from 'moment';

class LeadDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LeadCode: 0,
      isMobileView: false,
      isNotes: false
    };
  }

  componentDidMount() {
    const { LeadCode } = this.props.match.params;
    if (LeadCode) {
      this.setState({ LeadCode });
    }
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 767) {
      this.setState({ isMobileView: true });
    } else {
      this.setState({ isMobileView: false });
    }
  }
  handleNotesClick = () => {
    this.setState({ isNotes: !this.state.isNotes });
  };
  render() {
    const { TabPane } = Tabs;
    const { Option } = Select;
    const format = 'HH:mm';
    const { Panel } = Collapse;
    return (
      <div>
      {this.state.isMobileView ? (
      <div className="LeadDetailMobile">
        <div className="LeadDetail">
          <div className="leaDetLeft">
            <div className="LeUpper">
                <div className="leftimg">
                    <img src={Back} alt="Back" />
                     
                </div>
                <div className="userimg">
                    <img src={User} alt="User" />
                    <h4>Valerie W. Walton</h4>
                    <h5>Marketing Stratesist at Linkedin</h5>
                </div>
                <div className="step">
                  <ul>
                    <li className="active"></li>
                    <li className="active"></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
            </div>
            <div className="LeDown">
                <div className="row mb-3">
                  <div className="col-6 col-sm-6 col-md-6">
                    <h4 className="abou">About this Contact</h4>
                    <h4 className="dayago">65 days ago</h4>
                  </div>
                  <div className="col-6 col-sm-6 col-md-6">
                    <div className="rightUs">
                        <img src={User} alt="User" />
                        <div>
                          <h4 className="abou">Tejas Kadakia</h4>
                          <h4 className="dayago">Lead Owner</h4>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-6 col-sm-6">
                    <label className="abou">First Name</label>
                    <input type="text" defaultValue="Valeriw W" />
                  </div>
                  <div className="col-6 col-sm-6">
                    <label className="abou">Last Name</label>
                    <input type="text" defaultValue="Walton" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-6 col-sm-6">
                    <label className="abou">Email</label>
                    <input type="text" defaultValue="valeriewwalton@linkedin.com" />
                  </div>
                  <div className="col-6 col-sm-6">
                    <label className="abou">Contact</label>
                    <input type="text" defaultValue="+91- 9873470074" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-6 col-sm-6">
                    <label className="abou">Stage</label>
                    <input type="text" defaultValue="Qualified" />
                  </div>
                  <div className="col-6 col-sm-6">
                    <label className="abou">Source</label>
                    <input type="text" defaultValue="Website_Chatbot" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-6 col-sm-6">
                    <label className="abou">Status</label>
                    <input type="text" defaultValue="Warm" />
                  </div>
                </div>
                <div className="row mb-2 Up">
                  <div className="col-12 col-md-5">
                    <button>Update</button>
                  </div>
                </div>
            </div>
            <div className="UserInfoo">
                  <Collapse expandIconPosition={['right']}>
                    <Panel key="1" header={<label className="Les">Leads Info</label>}>
                      <div className="LeName">
                        <label>Lead Name</label>:
                        <label>Dinesh Sable</label>
                      </div>
                      <div className="LeName">
                        <label>Lead Mobile</label>:
                        <label>+91 7738148575</label>
                      </div>
                      <div className="LeName">
                        <label>Alt. No</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Gender</label>:
                        <label>Male</label>
                      </div>
                      <div className="LeName">
                        <label>Lead DOB</label>:
                        <label></label>
                      </div>
                      <div className="LeName">
                        <label>Lead Email</label>:
                        <label>dineshsabhle@gmail.com</label>
                      </div>
                      <div className="LeName">
                        <label>Empl. Type</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Marital Status</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Company Name</label>:
                        <label></label>
                      </div>
                      <div className="LeName">
                        <label>Anniv. Date</label>:
                        <label></label>
                      </div>
                      <div className="LeName">
                        <label>HNI</label>:
                        <label></label>
                      </div>
                      <div className="LeName">
                        <label>Lead Income</label>:
                        <label></label>
                      </div>
                      <div className="LeName">
                        <label>Additional Income</label>:
                        <label></label>
                      </div>
                      <div className="LeName">
                        <label>Occupation</label>:
                        <label></label>
                      </div>
                    </Panel>
                    <Panel key="2" header={<label className="Les">Contact Info</label>}>
                      <div className="LeName">
                        <label>Address</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>City</label>:
                        <label>Mumbai</label>
                      </div>
                    </Panel>
                    <Panel key="3" header={<label className="Les">Enquiry By</label>}>
                      <div className="LeName">
                        <label>Name</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Relationship</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Age</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Email</label>:
                        <label>Unknown</label>
                      </div>
                    </Panel>
                    <Panel key="4" header={<label className="Les">Enquiry Details</label>}>
                      <div className="LeName">
                        <label>MH Id/MR No</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Case Category</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Status</label>:
                        <label>CC Outbound FollowUp</label>
                      </div>
                      <div className="LeName">
                        <label>Sub Status</label>:
                        <label>Call Back</label>
                      </div>
                      <div className="LeName">
                        <label>Speciality</label>:
                        <label>-</label>
                      </div>
                      <div className="LeName">
                        <label>Lead Type</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Agr. Discount</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Creator</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Assign To</label>:
                        <label>Sneha</label>
                      </div>
                    </Panel>
                    <Panel key="5" header={<label className="Les">Enquiry Source</label>}>
                      <div className="LeName">
                        <label>Enquiry Type</label>:
                        <label>Campaign</label>
                      </div>
                      <div className="LeName">
                        <label>Enq. Sub. Type</label>:
                        <label>Birthing</label>
                      </div>
                      <div className="LeName">
                        <label>Lead Source Type</label>:
                        <label>Facebook</label>
                      </div>
                      <div className="LeName">
                        <label>Page/Form No.</label>:
                        <label>Dc_LG_FB_MumbaiBir..</label>
                      </div>
                    </Panel>
                    <Panel key="6" header={<label className="Les">Care Center Details</label>}>
                      <div className="LeName">
                        <label>Unit City</label>:
                        <label>Mumbai</label>
                      </div>
                      <div className="LeName">
                        <label>Unit</label>:
                        <label>Mumbai-Kharghar</label>
                      </div>
                    </Panel>
                    <Panel key="7" header={<label className="Les">Insurance Info</label>}>
                      <div className="LeName">
                        <label>Insurance Provider Name</label>:
                        <label>-</label>
                      </div>
                      <div className="LeName">
                        <label>Insurance Id</label>:
                        <label>Mumbai-Kharghar</label>
                      </div>
                      <div className="LeName">
                        <label>Preauthorization Code</label>:
                        <label>-</label>
                      </div>
                    </Panel>
                    <Panel key="8" header={<label className="Les">Maternity Info</label>}>
                      <div className="LeName">
                        <label>Consulting Doctor</label>:
                        <label>-</label>
                      </div>
                      <div className="LeName">
                        <label>First Day of Last Period(LMP)</label>:
                        <label>-</label>
                      </div>
                      <div className="LeName">
                        <label>Estimation Date of delivery(EDD)</label>:
                        <label>-</label>
                      </div>
                      <div className="LeName">
                        <label>Estimated gestational age</label>:
                        <label>-</label>
                      </div>
                    </Panel>
                  </Collapse>
            </div>
          </div>
          <div className="leaDetRight">
          <Tabs defaultActiveKey="1">
            <TabPane key="1" tab={<div><img src={History} alt="Histort" /><label className="His">History</label></div>}>
                <div className="HistoryMain">
                  <ul>
                    <li>
                      <Collapse>
                        <Panel header={
                          <div className="pannel">
                            <div className="lefSide">
                              <div className="rightUs">
                                  <div className="DateTime">
                                    <h4 className="abou">16 December</h4>
                                    <h4 className="dayago">5:30 PM</h4>
                                  </div>
                              </div>
                            </div>
                          </div>
                        } key="1">
                        <div className="History">
                            <div className="QuContent">
                                <span><img src={Doller} alt="User" /></span>
                                <div className="Quote">
                                  <h4 className="abou">Quote Sent</h4>
                                  <h4 className="dayago">Quote Sent to the prospect as his number was not reachable</h4>
                                </div>
                            </div>
                          <div className="RiSide">
                            <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                            </div>
                        </div>
                        </div>
                        </Panel>
                        <Panel header={
                          <div className="pannel">
                            <div className="lefSide">
                              <div className="rightUs">
                                  <div className="DateTime">
                                    <h4 className="abou">16 December</h4>
                                    <h4 className="dayago">5:30 PM</h4>
                                  </div>
                              </div>
                            </div>
                          </div>
                        } key="2">
                        <div className="History">
                            <div className="QuContent">
                                <span><img src={Email} alt="User" /></span>
                                <div className="Quote">
                                  <h4 className="abou">Email Opened <p style={{color:"#07A287",display:"inline-block",margin:"0"}}>+2</p></h4>
                                  <h4 className="dayago">Email Opened by the prospect</h4>
                                </div>
                            </div>
                          <div className="RiSide">
                            <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                            </div>
                        </div>
                        </div>
                        </Panel>
                        <Panel header={
                          <div className="pannel">
                            <div className="lefSide">
                              <div className="rightUs">
                                  <div className="DateTime">
                                    <h4 className="abou">16 December</h4>
                                    <h4 className="dayago">5:30 PM</h4>
                                  </div>
                              </div>
                            </div>
                          </div>
                        } key="3">
                        <div className="History">
                              <div className="QuContent">
                                <span><img src={Doller} alt="User" /></span>
                                <div className="Quote">
                                  <h4 className="abou">Quote Sent</h4>
                                  <h4 className="dayago">Quote Sent to the prospect as his number was not reachable</h4>
                                </div>
                              </div>
                          <div className="RiSide">
                            <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                            </div>
                        </div>
                        </div>
                        </Panel>
                      </Collapse>
                    </li>
                  </ul>
                </div>
            </TabPane>
            <TabPane key="2" tab={<div><img src={Task} alt="Task" /><label className="His">Task</label></div>}>
                <div className="TaskMain">
                  <ul>
                    <li>
                    <button className="EditPro">Edit Status</button>
                    <Collapse>
                        <Panel header={
                          <div className="pannel">
                            <div className="lefSide">
                              <div className="rightUs">
                                  <div className="DateTime">
                                    <h4 className="abou">16 December</h4>
                                    <h4 className="dayago">5:30 PM</h4>
                                  </div>
                              </div>
                              <button className="planned">Planned</button>
                            </div>
                          </div>
                        } key="1">
                        <div className="Taskk">
                            <div className="QuContent">
                                <div className="Quote">
                                  <h4 className="dayago"><span className="abou">Task: </span>Call - Need to call back to customer.</h4>
                                  <h4 className="dayago"><span className="abou">Comment: </span>Quote Sent to the prospect as his number was not reachable</h4>
                                </div>
                            </div>
                          <div className="RiSide">
                            <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                            </div>
                        </div>
                        </div>
                        </Panel>
                        <Panel header={
                          <div className="pannel">
                            <div className="lefSide">
                              <div className="rightUs">
                                  <div className="DateTime">
                                    <h4 className="abou">16 December</h4>
                                    <h4 className="dayago">5:30 PM</h4>
                                  </div>
                              </div>
                              <button className="Pastt">Past</button>
                            </div>
                          </div>
                        } key="2">
                        <div className="Taskk">
                            <div className="QuContent">
                                <div className="Quote">
                                  <h4 className="dayago"><span className="abou">Task: </span>Call - Need to call back to customer.</h4>
                                  <h4 className="dayago"><span className="abou">Comment: </span>Quote Sent to the prospect as his number was not reachable</h4>
                                </div>
                            </div>
                          <div className="RiSide">
                            <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                            </div>
                        </div>
                        </div>
                        </Panel>
                        <Panel header={
                          <div className="pannel">
                            <div className="lefSide">
                              <div className="rightUs">
                                  <div className="DateTime">
                                    <h4 className="abou">16 December</h4>
                                    <h4 className="dayago">5:30 PM</h4>
                                  </div>
                              </div>
                              <button className="Completee">Complete</button>
                            </div>
                          </div>
                        } key="3">
                        <div className="Taskk">
                            <div className="QuContent">
                                <div className="Quote">
                                  <h4 className="dayago"><span className="abou">Task: </span>Call - Need to call back to customer.</h4>
                                  <h4 className="dayago"><span className="abou">Comment: </span>Quote Sent to the prospect as his number was not reachable</h4>
                                </div>
                            </div>
                          <div className="RiSide">
                            <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                            </div>
                        </div>
                        </div>
                        </Panel>
                      </Collapse>
                    </li>
                  </ul>
                </div>
            </TabPane>
            <TabPane key="3" tab={<div><img src={Notes} alt="Notes" /><label className="His">Notes</label></div>}>
                <div className="NotesMain">
                  <ul>
                    <li>
                      <Collapse>
                        <Panel header={
                          <div className="pannel">
                            <div className="lefSide">
                              <div className="rightUs">
                                  <div className="DateTime">
                                    <h4 className="abou">16 December</h4>
                                    <h4 className="dayago">5:30 PM</h4>
                                  </div>
                              </div>
                            </div>
                          </div>
                        } key="1">
                        <div className="Notes">
                        <div className="QuContent">
                                <span><img src={NotesSolid} alt="User" /></span>
                                <div className="Quote">
                                  <h4 className="dayago">Email Opened by the prospect</h4>
                                </div>
                              </div>
                        <div className="RiSide">
                          <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                          </div>
                        </div>
                      </div>
                        </Panel>
                        <Panel header={
                          <div className="pannel">
                            <div className="lefSide">
                              <div className="rightUs">
                                  <div className="DateTime">
                                    <h4 className="abou">16 December</h4>
                                    <h4 className="dayago">5:30 PM</h4>
                                  </div>
                              </div>
                            </div>
                          </div>
                        } key="2">
                        <div className="Notes">
                        <div className="QuContent">
                                <span><img src={NotesSolid} alt="User" /></span>
                                <div className="Quote">
                                  <h4 className="dayago">Email Opened by the prospect</h4>
                                </div>
                              </div>
                        <div className="RiSide">
                          <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                          </div>
                        </div>
                      </div>
                        </Panel>
                        <Panel header={
                          <div className="pannel">
                            <div className="lefSide">
                              <div className="rightUs">
                                  <div className="DateTime">
                                    <h4 className="abou">16 December</h4>
                                    <h4 className="dayago">5:30 PM</h4>
                                  </div>
                              </div>
                            </div>
                          </div>
                        } key="3">
                        <div className="Notes">
                        <div className="QuContent">
                                <span><img src={NotesSolid} alt="User" /></span>
                                <div className="Quote">
                                  <h4 className="dayago">Email Opened by the prospect</h4>
                                </div>
                              </div>
                        <div className="RiSide">
                          <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                          </div>
                        </div>
                      </div>
                        </Panel>
                        <Panel header={
                          <div className="pannel">
                            <div className="lefSide">
                              <div className="rightUs">
                                  <div className="DateTime">
                                    <h4 className="abou">16 December</h4>
                                    <h4 className="dayago">5:30 PM</h4>
                                  </div>
                              </div>
                            </div>
                          </div>
                        } key="4">
                        <div className="Notes">
                        <div className="QuContent">
                                <span><img src={NotesSolid} alt="User" /></span>
                                <div className="Quote">
                                  <h4 className="dayago">Email Opened by the prospect</h4>
                                </div>
                              </div>
                        <div className="RiSide">
                          <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                          </div>
                        </div>
                      </div>
                        </Panel>
                      </Collapse>
                    </li>
                  </ul>
                </div>
            </TabPane>
            <TabPane key="4" tab={<div><img src={Email} alt="Email" /><label className="His">Email</label></div>}>
                <div className="EmailMain">
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                        <div className="ToEmai">
                            <label>To</label>
                            <input type="text" defaultValue="valeriewalton@linkedin.com" />
                        </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                        <div className="ToEmai">
                            <label>from</label>
                            <input type="text" defaultValue="Tejas (tejaskadakia@easyrewadz.com)" />
                            <label className="BCC"><span>cc</span><span>bcc</span></label>
                        </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                        <div className="ToEmai">
                            <label>Subject</label>
                            <input type="text" defaultValue="Valeriw W" />
                        </div>
                    </div>
                  </div>
                  <div className="row mb-3 SendBtn">
                      <div className="col-12 col-md-12">
                        <CKEditor
                          config={{
                            toolbar: [
                              {
                                name: "basicstyles",
                                items: ["Bold", "Italic", "Strike"],
                              },
                              {
                                name: "styles",
                                items: ["Styles", "Format"],
                              },
                              {
                                name: "paragraph",
                                items: ["NumberedList", "BulletedList"],
                              },
                              {
                                name: "links",
                                items: ["Link", "Unlink"],
                              },
                              {
                                name: "insert",
                                items: ["Image", "Table"],
                              },
                              {
                                name: "tools",
                                items: ["Maximize"],
                              },
                              {
                                name: "editing",
                                items: ["Scayt"],
                              },
                            ],
                          }}
                        />
                        <button>Send</button>
                      </div>
                  </div>
                </div>
            </TabPane>
            <TabPane key="5" tab={<div><img src={Call} alt="Call" /><label className="His">Call</label></div>}>
                <div className="CallMain">
                  <ul>
                    <li>
                    <Collapse>
                        <Panel header={
                          <div className="pannel">
                            <div className="lefSide">
                              <div className="rightUs">
                                  <div className="DateTime">
                                    <h4 className="abou">16 December</h4>
                                    <h4 className="dayago">5:30 PM</h4>
                                  </div>
                              </div>
                            </div>
                          </div>
                        } key="1">
                        <div className="Calll">
                              <div className="QuContent">
                                <div className="Quote">
                                  <h4 className="abou">Phone Call</h4>
                                  <h4 className="dayago">Contact +919873470074</h4>
                                </div>
                              </div>
                          <div className="RiSide">
                            <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className="CallRecord">
                            <label>Call Record</label>
                        </div>
                        </Panel>
                        <Panel header={
                          <div className="pannel">
                            <div className="lefSide">
                              <div className="rightUs">
                                  <div className="DateTime">
                                    <h4 className="abou">16 December</h4>
                                    <h4 className="dayago">5:30 PM</h4>
                                  </div>
                              </div>
                            </div>
                          </div>
                        } key="2">
                        <div className="Calll">
                              <div className="QuContent">
                                <div className="Quote">
                                  <h4 className="abou">Phone Call</h4>
                                  <h4 className="dayago">Contact +919873470074</h4>
                                </div>
                              </div>
                          <div className="RiSide">
                            <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className="CallRecord">
                            <label>Call Record</label>
                        </div>
                        </Panel>
                        <Panel header={
                          <div className="pannel">
                            <div className="lefSide">
                              <div className="rightUs">
                                  <div className="DateTime">
                                    <h4 className="abou">16 December</h4>
                                    <h4 className="dayago">5:30 PM</h4>
                                  </div>
                              </div>
                            </div>
                          </div>
                        } key="3">
                        <div className="Calll">
                              <div className="QuContent">
                                <div className="Quote">
                                  <h4 className="abou">Phone Call</h4>
                                  <h4 className="dayago">Contact +919873470074</h4>
                                </div>
                              </div>
                          <div className="RiSide">
                            <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className="CallRecord">
                            <label>Call Record</label>
                        </div>
                        </Panel>
                        </Collapse>
                    </li>
                  </ul>
                </div>
            </TabPane>
            <TabPane key="6" tab={<div><img src={Meet} alt="Meet" /><label className="His">Meet</label></div>}>
                <div className="MeetMain">
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                        <div className="ToEmai">
                            <label>To</label>
                            <input type="text" defaultValue="valeriewalton@linkedin.com" />
                        </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                        <div className="ToEmai">
                            <label>from</label>
                            <input type="text" defaultValue="Tejas (tejaskadakia@easyrewadz.com)" />
                        </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-6 mb-3">
                        <div className="ToEmai1">
                            <label>Date</label>
                            <DatePicker />
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3">
                        <div className="ToEmai1">
                            <label>Start Time</label>
                            <TimePicker defaultValue={moment('12:08', format)} format={format} />
                        </div>
                    </div>
                    <div className="col-6 col-sm-6 col-md-3">
                        <div className="ToEmai1">
                            <label>End Time</label>
                            <TimePicker defaultValue={moment('12:08', format)} format={format} />
                        </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                        <div className="ToEmai1">
                            <label>Attendndees</label>
                            <Select defaultValue="Arun@linked.com">
                              <Option value="jack">Arun@linked.com</Option>
                              <Option value="lucy">Richa@linked.com</Option>
                            </Select>
                        </div>
                    </div>
                  </div>
                  <div className="row mb-3 SendBtn">
                      <div className="col-12 col-md-12">
                        <CKEditor
                          config={{
                            toolbar: [
                              {
                                name: "basicstyles",
                                items: ["Bold", "Italic", "Strike"],
                              },
                              {
                                name: "styles",
                                items: ["Styles", "Format"],
                              },
                              {
                                name: "paragraph",
                                items: ["NumberedList", "BulletedList"],
                              },
                              {
                                name: "links",
                                items: ["Link", "Unlink"],
                              },
                              {
                                name: "insert",
                                items: ["Image", "Table"],
                              },
                              {
                                name: "tools",
                                items: ["Maximize"],
                              },
                              {
                                name: "editing",
                                items: ["Scayt"],
                              },
                            ],
                          }}
                        />
                        <button>Send</button>
                      </div>
                  </div>
                </div>
            </TabPane>
            <TabPane key="7" tab={<div className="SmS"><img src={SmS} alt="Sms" /><label className="His">Sms</label></div>}>
                <div className="SmsMain">
                  <label>Send SMS</label>
                  <textarea rows=""></textarea>
                  <div className="cansen">
                    <button type="button" className="can">Cancel</button>
                    <button type="button" className="sen">Send</button>
                  </div>
                </div>
            </TabPane>
          </Tabs>
        </div>
        </div>
      </div>
      ) : (
      <div className="LeadDetail">
        <div className="leaDetLeft">
            <div className="LeUpper">
                <div className="leftimg">
                    <img src={Back} alt="Back" />
                    
                </div>
                <div className="userimg">
                    <img src={User} alt="User" />
                    <h4>Valerie W. Walton</h4>
                    <h5>Marketing Stratesist at Linkedin </h5>
                </div>
                <div className="step">
                  <ul>
                    <li className="active"></li>
                    <li className="active"></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
            </div>
            <div className="LeDown">
                <div className="row mb-3">
                  <div className="col-6 col-sm-6 col-md-6">
                    <h4 className="abou">About this Contact</h4>
                    <h4 className="dayago">65 days ago</h4>
                  </div>
                  <div className="col-6 col-sm-6 col-md-6">
                    <div className="rightUs">
                        <img src={User} alt="User" />
                        <div>
                          <h4 className="abou">Tejas Kadakia</h4>
                          <h4 className="dayago">Lead Owner</h4>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-12">
                    <label className="abou">First Name</label>
                    <input type="text" defaultValue="Valeriw W" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-12">
                    <label className="abou">Last Name</label>
                    <input type="text" defaultValue="Walton" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-12">
                    <label className="abou">Email</label>
                    <input type="text" defaultValue="valeriewwalton@linkedin.com" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-12">
                    <label className="abou">Contact</label>
                    <input type="text" defaultValue="+91- 9873470074" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-12">
                    <label className="abou">Stage</label>
                    <input type="text" defaultValue="Qualified" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-12">
                    <label className="abou">Source</label>
                    <input type="text" defaultValue="Website_Chatbot" />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-12 col-md-12">
                    <label className="abou">Status</label>
                    <input type="text" defaultValue="Warm" />
                  </div>
                </div>
                <div className="row mb-2 Up">
                  <div className="col-12 col-md-5">
                    <button>Update</button>
                  </div>
                </div>
            </div>
              <div className="UserInfoo">
                  <Collapse expandIconPosition={['right']}>
                    <Panel key="1" header={<label className="Les">Leads Info</label>}>
                      <div className="LeName">
                        <label>Lead Name</label>:
                        <label>Dinesh Sable</label>
                      </div>
                      <div className="LeName">
                        <label>Lead Mobile</label>:
                        <label>+91 7738148575</label>
                      </div>
                      <div className="LeName">
                        <label>Alt. No</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Gender</label>:
                        <label>Male</label>
                      </div>
                      <div className="LeName">
                        <label>Lead DOB</label>:
                        <label></label>
                      </div>
                      <div className="LeName">
                        <label>Lead Email</label>:
                        <label>dineshsabhle@gmail.com</label>
                      </div>
                      <div className="LeName">
                        <label>Empl. Type</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Marital Status</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Company Name</label>:
                        <label></label>
                      </div>
                      <div className="LeName">
                        <label>Anniv. Date</label>:
                        <label></label>
                      </div>
                      <div className="LeName">
                        <label>HNI</label>:
                        <label></label>
                      </div>
                      <div className="LeName">
                        <label>Lead Income</label>:
                        <label></label>
                      </div>
                      <div className="LeName">
                        <label>Additional Income</label>:
                        <label></label>
                      </div>
                      <div className="LeName">
                        <label>Occupation</label>:
                        <label></label>
                      </div>
                    </Panel>
                    <Panel key="2" header={<label className="Les">Contact Info</label>}>
                      <div className="LeName">
                        <label>Address</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>City</label>:
                        <label>Mumbai</label>
                      </div>
                    </Panel>
                    <Panel key="3" header={<label className="Les">Enquiry By</label>}>
                      <div className="LeName">
                        <label>Name</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Relationship</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Age</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Email</label>:
                        <label>Unknown</label>
                      </div>
                    </Panel>
                    <Panel key="4" header={<label className="Les">Enquiry Details</label>}>
                      <div className="LeName">
                        <label>MH Id/MR No</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Case Category</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Status</label>:
                        <label>CC Outbound FollowUp</label>
                      </div>
                      <div className="LeName">
                        <label>Sub Status</label>:
                        <label>Call Back</label>
                      </div>
                      <div className="LeName">
                        <label>Speciality</label>:
                        <label>-</label>
                      </div>
                      <div className="LeName">
                        <label>Lead Type</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Agr. Discount</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Creator</label>:
                        <label>Unknown</label>
                      </div>
                      <div className="LeName">
                        <label>Assign To</label>:
                        <label>Sneha</label>
                      </div>
                    </Panel>
                    <Panel key="5" header={<label className="Les">Enquiry Source</label>}>
                      <div className="LeName">
                        <label>Enquiry Type</label>:
                        <label>Campaign</label>
                      </div>
                      <div className="LeName">
                        <label>Enq. Sub. Type</label>:
                        <label>Birthing</label>
                      </div>
                      <div className="LeName">
                        <label>Lead Source Type</label>:
                        <label>Facebook</label>
                      </div>
                      <div className="LeName">
                        <label>Page/Form No.</label>:
                        <label>Dc_LG_FB_MumbaiBir..</label>
                      </div>
                    </Panel>
                    <Panel key="6" header={<label className="Les">Care Center Details</label>}>
                      <div className="LeName">
                        <label>Unit City</label>:
                        <label>Mumbai</label>
                      </div>
                      <div className="LeName">
                        <label>Unit</label>:
                        <label>Mumbai-Kharghar</label>
                      </div>
                    </Panel>
                    <Panel key="7" header={<label className="Les">Insurance Info</label>}>
                      <div className="LeName">
                        <label>Insurance Provider Name</label>:
                        <label>-</label>
                      </div>
                      <div className="LeName">
                        <label>Insurance Id</label>:
                        <label>Mumbai-Kharghar</label>
                      </div>
                      <div className="LeName">
                        <label>Preauthorization Code</label>:
                        <label>-</label>
                      </div>
                    </Panel>
                    <Panel key="8" header={<label className="Les">Maternity Info</label>}>
                      <div className="LeName">
                        <label>Consulting Doctor</label>:
                        <label>-</label>
                      </div>
                      <div className="LeName">
                        <label>First Day of Last Period(LMP)</label>:
                        <label>-</label>
                      </div>
                      <div className="LeName">
                        <label>Estimation Date of delivery(EDD)</label>:
                        <label>-</label>
                      </div>
                      <div className="LeName">
                        <label>Estimated gestational age</label>:
                        <label>-</label>
                      </div>
                    </Panel>
                  </Collapse>
                </div>
        </div>
        <div className="leaDetRight">
          <Tabs defaultActiveKey="1">
            <TabPane key="1" tab={<div><img src={History} alt="Histort" /><label className="His">History</label></div>}>
                <div className="HistoryMain">
                  <ul>
                    <li>
                      <div className="History">
                        <div className="lefSide">
                          <div className="rightUs">
                              <div className="DateTime">
                                <h4 className="abou">16 December</h4>
                                <h4 className="dayago">5:30 PM</h4>
                              </div>
                              <div className="QuContent">
                                <span><img src={Doller} alt="User" /></span>
                                <div className="Quote">
                                  <h4 className="abou">Quote Sent</h4>
                                  <h4 className="dayago">Quote Sent to the prospect as his number was not reachable</h4>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div className="RiSide">
                          <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                          </div>
                        </div>
                        </div>
                    </li>
                    <li>
                      <div className="History">
                        <div className="lefSide">
                          <div className="rightUs">
                              <div className="DateTime">
                                <h4 className="abou">16 December</h4>
                                <h4 className="dayago">5:30 PM</h4>
                              </div>
                              <div className="QuContent">
                                <span><img src={Email} alt="User" /></span>
                                <div className="Quote">
                                  <h4 className="abou">Email Opened <p style={{color:"#07A287",display:"inline-block",margin:"0"}}>+2</p></h4>
                                  <h4 className="dayago">Email Opened by the prospect</h4>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div className="RiSide">
                          <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                          </div>
                        </div>
                        </div>
                    </li>
                    <li>
                      <div className="History">
                        <div className="lefSide">
                          <div className="rightUs">
                              <div className="DateTime">
                                <h4 className="abou">16 December</h4>
                                <h4 className="dayago">5:30 PM</h4>
                              </div>
                              <div className="QuContent">
                                <span><img src={Doller} alt="User" /></span>
                                <div className="Quote">
                                  <h4 className="abou">Quote Sent</h4>
                                  <h4 className="dayago">Quote Sent to the prospect as his number was not reachable</h4>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div className="RiSide">
                          <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                          </div>
                        </div>
                        </div>
                    </li>
                  </ul>
                </div>
            </TabPane>
            <TabPane key="2" tab={<div><img src={Task} alt="Task" /><label className="His">Task</label></div>}>
                <div className="TaskMain">
                  <ul>
                    <li>
                    <button className="planned">Planned</button>
                    <button className="EditPro">Edit Status</button>
                      <div className="Taskk">
                        <div className="lefSide">
                          <div className="rightUs">
                              <div className="DateTime">
                                <h4 className="abou">16 December</h4>
                                <h4 className="dayago">5:30 PM</h4>
                              </div>
                              <div className="QuContent">
                                <div className="Quote">
                                  <h4 className="dayago"><span className="abou">Task: </span>Call - Need to call back to customer.</h4>
                                  <h4 className="dayago"><span className="abou">Comment: </span>Quote Sent to the prospect as his number was not reachable</h4>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div className="RiSide">
                          <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                          </div>
                        </div>
                        </div>
                    </li>
                    <li>
                      <button className="Pastt">Past</button>
                      <div className="Taskk">
                        <div className="lefSide">
                          <div className="rightUs">
                              <div className="DateTime">
                                <h4 className="abou">16 December</h4>
                                <h4 className="dayago">5:30 PM</h4>
                              </div>
                              <div className="QuContent">
                                <div className="Quote">
                                  <h4 className="dayago"><span className="abou">Task: </span>Call - Need to call back to customer.</h4>
                                  <h4 className="dayago"><span className="abou">Comment: </span>Quote Sent to the prospect as his number was not reachable</h4>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div className="RiSide">
                          <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                          </div>
                        </div>
                        </div>
                    </li>
                    <li>
                    <button className="Completee">Complete</button>
                      <div className="Taskk">
                        <div className="lefSide">
                          <div className="rightUs">
                              <div className="DateTime">
                                <h4 className="abou">16 December</h4>
                                <h4 className="dayago">5:30 PM</h4>
                              </div>
                              <div className="QuContent">
                                <div className="Quote">
                                  <h4 className="dayago"><span className="abou">Task: </span>Call - Need to call back to customer.</h4>
                                  <h4 className="dayago"><span className="abou">Comment: </span>Quote Sent to the prospect as his number was not reachable</h4>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div className="RiSide">
                          <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                          </div>
                        </div>
                        </div>
                    </li>
                  </ul>
                </div>
            </TabPane>
            <TabPane key="3" tab={<div><img src={Notes} alt="Notes" /><label className="His">Notes</label></div>}>
                <div className="NotesMain">
                  <ul>
                    <li>
                      <div className="Notes">
                        <div className="lefSide">
                          <div className="rightUs">
                              <div className="DateTime">
                                <h4 className="abou">16 December</h4>
                                <h4 className="dayago">5:30 PM</h4>
                              </div>
                              <div className="QuContent">
                                <span><img src={NotesSolid} alt="User" /></span>
                                <div className="Quote">
                                  <h4 className="dayago">Quote Sent to the prospect as his number was not reachable</h4>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div className="RiSide">
                          <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                          </div>
                        </div>
                        </div>
                    </li>
                    <li>
                      <div className="Notes">
                        <div className="lefSide">
                          <div className="rightUs">
                              <div className="DateTime">
                                <h4 className="abou">16 December</h4>
                                <h4 className="dayago">5:30 PM</h4>
                              </div>
                              <div className="QuContent">
                                <span><img src={NotesSolid} alt="User" /></span>
                                <div className="Quote">
                                  <h4 className="dayago">Email Opened by the prospect</h4>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div className="RiSide">
                          <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                          </div>
                        </div>
                        </div>
                    </li>
                    <li>
                      <div className="Notes">
                        <div className="lefSide">
                          <div className="rightUs">
                              <div className="DateTime">
                                <h4 className="abou">16 December</h4>
                                <h4 className="dayago">5:30 PM</h4>
                              </div>
                              <div className="QuContent">
                                <span><img src={NotesSolid} alt="User" /></span>
                                <div className="Quote">
                                  <h4 className="dayago">Quote Sent to the prospect as his number was not reachable</h4>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div className="RiSide">
                          <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                          </div>
                        </div>
                        </div>
                    </li>
                  </ul>
                </div>
            </TabPane>
            <TabPane key="4" tab={<div><img src={Email} alt="Email" /><label className="His">Email</label></div>}>
                <div className="EmailMain">
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                        <div className="ToEmai">
                            <label>To</label>
                            <input type="text" defaultValue="valeriewalton@linkedin.com" />
                        </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                        <div className="ToEmai">
                            <label>from</label>
                            <input type="text" defaultValue="Tejas (tejaskadakia@easyrewadz.com)" />
                            <label className="BCC"><span>cc</span><span>bcc</span></label>
                        </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                        <div className="ToEmai">
                            <label>Subject</label>
                            <input type="text" defaultValue="Valeriw W" />
                        </div>
                    </div>
                  </div>
                  <div className="row mb-3 SendBtn">
                      <div className="col-12 col-md-12">
                        <CKEditor
                          config={{
                            toolbar: [
                              {
                                name: "basicstyles",
                                items: ["Bold", "Italic", "Strike"],
                              },
                              {
                                name: "styles",
                                items: ["Styles", "Format"],
                              },
                              {
                                name: "paragraph",
                                items: ["NumberedList", "BulletedList"],
                              },
                              {
                                name: "links",
                                items: ["Link", "Unlink"],
                              },
                              {
                                name: "insert",
                                items: ["Image", "Table"],
                              },
                              {
                                name: "tools",
                                items: ["Maximize"],
                              },
                              {
                                name: "editing",
                                items: ["Scayt"],
                              },
                            ],
                          }}
                        />
                        <button>Send</button>
                      </div>
                  </div>
                </div>
            </TabPane>
            <TabPane key="5" tab={<div><img src={Call} alt="Call" /><label className="His">Call</label></div>}>
                <div className="CallMain">
                  <ul>
                    <li>
                      <div className="Calll">
                        <div className="lefSide">
                          <div className="rightUs">
                              <div className="DateTime">
                                <h4 className="abou">16 June</h4>
                                <h4 className="dayago">5:30 PM</h4>
                              </div>
                              <div className="QuContent">
                                <div className="Quote">
                                  <h4 className="abou">Phone Call</h4>
                                  <h4 className="dayago">Contact +919873470074</h4>
                                </div>
                                <div className="CallRecord">
                                    <label>Call Record</label>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div className="RiSide">
                          <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                          </div>
                        </div>
                        </div>
                    </li>
                    <li>
                      <div className="Calll">
                        <div className="lefSide">
                            <div className="rightUs">
                                <div className="DateTime">
                                  <h4 className="abou">16 June</h4>
                                  <h4 className="dayago">5:30 PM</h4>
                                </div>
                                <div className="QuContent">
                                  <div className="Quote">
                                    <h4 className="abou">Phone Call</h4>
                                    <h4 className="dayago">Contact +919873470074</h4>
                                  </div>
                                  <div className="CallRecord">
                                    <label>Call Record</label>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div className="RiSide">
                          <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                          </div>
                        </div>
                        </div>
                    </li>
                    <li>
                      <div className="Calll">
                        <div className="lefSide">
                          <div className="rightUs">
                                <div className="DateTime">
                                  <h4 className="abou">16 June</h4>
                                  <h4 className="dayago">5:30 PM</h4>
                                </div>
                                <div className="QuContent">
                                  <div className="Quote">
                                    <h4 className="abou">Phone Call</h4>
                                    <h4 className="dayago">Contact +919873470074</h4>
                                  </div>
                                  <div className="CallRecord">
                                    <label>Call Record</label>
                                  </div>
                                </div>
                            </div>
                        </div>
                        <div className="RiSide">
                          <div className="rightUs">
                                <img src={User} alt="User" />
                                <div>
                                  <h4 className="abou">Tejas Kadakia</h4>
                                  <h4 className="dayago">Lead Owner</h4>
                                </div>
                          </div>
                        </div>
                        </div>
                    </li>
                  </ul>
                </div>
            </TabPane>
            <TabPane key="6" tab={<div><img src={Meet} alt="Meet" /><label className="His">Meet</label></div>}>
                <div className="MeetMain">
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                        <div className="ToEmai">
                            <label>To</label>
                            <input type="text" defaultValue="valeriewalton@linkedin.com" />
                        </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                        <div className="ToEmai">
                            <label>from</label>
                            <input type="text" defaultValue="Tejas (tejaskadakia@easyrewadz.com)" />
                        </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-6">
                        <div className="ToEmai1">
                            <label>Date</label>
                            <DatePicker />
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="ToEmai1">
                            <label>Start Time</label>
                            <TimePicker defaultValue={moment('12:08', format)} format={format} />
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="ToEmai1">
                            <label>End Time</label>
                            <TimePicker defaultValue={moment('12:08', format)} format={format} />
                        </div>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                        <div className="ToEmai1">
                            <label>Attendndees</label>
                            <Select defaultValue="Arun@linked.com">
                              <Option value="jack">Arun@linked.com</Option>
                              <Option value="lucy">Richa@linked.com</Option>
                            </Select>
                        </div>
                    </div>
                  </div>
                  <div className="row mb-3 SendBtn">
                      <div className="col-12 col-md-12">
                        <CKEditor
                          config={{
                            toolbar: [
                              {
                                name: "basicstyles",
                                items: ["Bold", "Italic", "Strike"],
                              },
                              {
                                name: "styles",
                                items: ["Styles", "Format"],
                              },
                              {
                                name: "paragraph",
                                items: ["NumberedList", "BulletedList"],
                              },
                              {
                                name: "links",
                                items: ["Link", "Unlink"],
                              },
                              {
                                name: "insert",
                                items: ["Image", "Table"],
                              },
                              {
                                name: "tools",
                                items: ["Maximize"],
                              },
                              {
                                name: "editing",
                                items: ["Scayt"],
                              },
                            ],
                          }}
                        />
                        <button>Send</button>
                      </div>
                  </div>
                </div>
            </TabPane>
            <TabPane key="7" tab={<div className="SmS"><img src={SmS} alt="Sms" /><label className="His">Sms</label></div>}>
                <div className="SmsMain">
                  <label>Send SMS</label>
                  <textarea rows=""></textarea>
                  <div className="cansen">
                    <button type="button" className="can">Cancel</button>
                    <button type="button" className="sen">Send</button>
                  </div>
                </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
      )}
      </div>
    );
  }
}

export default LeadDetails;
