import React, { Component } from 'react'
import LoginLogo from "./../../assets/images/logo.png";
import "./../../assets/css/Login/login.css"

export class ChangePassword extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    
    render() {
        return (
            <div>
                <div className="LoginBack">
                    <div className="center" style={{position:"relative"}}>
                        <div className="loginlogo">
                            <img src={LoginLogo} alt="Logo" />
                        </div>
                        <div className="mb-3">
                            <input type="password" className="email" placeholder="Enter Old Password*" />
                        </div>
                        <div className="mb-3">
                            <input type="password" className="email" placeholder="Enter New Password*" />
                        </div>
                        <div className="mb-3">
                            <input type="password" className="email" placeholder="Enter Confirm Password*" />
                        </div>
                        <div className="mb-3">
                            <button type="button" className="loginbtn">Set Password</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangePassword;
