import React, { Component } from "react";
import Add from "./../../../assets/images/add.svg";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import AddNote from "./../../../assets/images/AddNote.svg";
import { Input, Radio, Select, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import Modal from "react-responsive-modal";
import FileUpload from "./../../../assets/images/upload.png";
import Dropzone from "react-dropzone";

class Category extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createCategory: false,
      Single: true,
      Multiple: false,
      fileName: "",
      fileN: [],    
    };
  }
  handleCreateCategotyOpen() {
    this.setState({ createCategory: true });
  }
  handleCreateCategoryClose() {
    this.setState({ createCategory: false });
  }
  start = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  handleRedioButtonChange(check) {
    if (check === "Single") {
      this.setState({
        Single: true,
        Multiple: false,
      });
    } else {
      this.setState({
        Single: false,
        Multiple: true,
      });
    }
  }
  fileUpload = (e) => {
    var allFiles = [];
    var selectedFiles = e;
    if (selectedFiles) {
      allFiles.push(selectedFiles[0]);

      this.setState({
        fileN: allFiles,
        fileName: allFiles[0].name,
      });
    }
};
  render() {
    const data = [
      {
        key: "1",
        brandName: "Bata",
        category: "Appointment",
        subCategory: "Consultation",
        issueType: "Booked Appointment",
      },
      {
        key: "2",
        brandName: "Jack n Jones",
        category: "Tshirts",
        subCategory: "Men Tshirts",
        issueType: "Color Issue",
      },
      {
        key: "3",
        brandName: "Layasa",
        category: "Retail Complaint",
        subCategory: "Shoe Issue",
        issueType: "Broken Shoe Issue",
      },
    ];
    const { Option } = Select;
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <div className="CategryMain">
        <div className="TasType">
          <div className="Hierdf">
            <div className="hierleft">
              <h4>
                Category
                <img
                  src={Add}
                  alt="add"
                  onClick={this.handleCreateCategotyOpen.bind(this)}
                />
              </h4>
            </div>
            <div className="hierright">
              <Input
                placeholder="Search by brand name, category..."
                prefix={<SearchOutlined />}
              />
            </div>
          </div>
          {/* <div className="hierdown">
                        <label><img src={CloseCircle} alt="Delete" />Delete</label>
                        <label><img src={Download} alt="Delete" />Excel</label>
                    </div> */}
          <div className="CategiryTable">
            <Table
              rowSelection={rowSelection}
              columns={[
                {
                  title: "Brand Name",
                  dataIndex: "brandName",
                  key: "brandName",
                },
                {
                  title: "Category",
                  dataIndex: "category",
                  key: "category",
                },
                {
                  title: "Sub Category",
                  key: "subCategory",
                  dataIndex: "subCategory",
                },
                {
                  title: "Issue Type",
                  key: "issueType",
                  dataIndex: "issueType",
                },
                {
                  title: "Status",
                  key: "status",
                  dataIndex: "status",
                  render: (row, item) => {
                    return (
                      <div className="Status">
                        <Select defaultValue="Active">
                          <Option value="Active">Active</Option>
                          <Option value="Inactive">Inactive</Option>
                        </Select>
                      </div>
                    );
                  },
                },
                {
                  title: "Actions",
                  key: "actions",
                  dataIndex: "actions",
                  render: (row, item) => {
                    return (
                      <div className="Actionic">
                        <img
                          src={AddNote}
                          alt="Edit"
                          onClick={this.handleCreateCategotyOpen.bind(this)}
                        />
                        <img src={CloseCircle} alt="delete" />
                      </div>
                    );
                  },
                },
              ]}
              dataSource={data}
              pagination={false}
            />
          </div>
          {/* Create Category */}
          <Modal
            open={this.state.createCategory}
            onClose={this.handleCreateCategoryClose.bind(this)}
            modalId="CreateCategoryModal"
            overlayId="overlay"
          >
            <div className="createCategory">
              <div className="hier mb-3">
                <label>Create Category</label>
              </div>
              <div className="mb-2">
                <Radio
                  type="radio"
                  name="Single"
                  checked={this.state.Single}
                  onChange={this.handleRedioButtonChange.bind(this, "Single")}
                >
                  Single
                </Radio>
                <Radio
                  type="radio"
                  name="Single"
                  checked={this.state.Multiple}
                  onChange={this.handleRedioButtonChange.bind(this, "Multiple")}
                >
                  Multiple
                </Radio>
              </div>
              {this.state.Single && (
                <>
                  <div className="row mb-3">
                    <div className="col-12 col-md-6">
                      <label className="desig">
                        Brand<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select defaultValue="Click to Select">
                        <Option value="jackjoes">Jack n Joes and 2 more</Option>
                      </Select>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="desig">
                        Category<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select defaultValue="Click to Select">
                        <Option value="manshoes">Men shoes and 2 more</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-6">
                      <label className="desig">
                        Sub Category<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select defaultValue="Click to Select">
                        <Option value="manshoes">Shoe Issue and 2 more</Option>
                      </Select>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="desig">
                        Issue Type<span style={{ color: "red" }}>*</span>
                      </label>
                      <Select defaultValue="Click to Select">
                        <Option value="manshoes">Color Issue</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="desig">Status</label>
                      <Select defaultValue="Click to Select">
                        <Option value="active">Active</Option>
                        <Option value="inactive">Inactive</Option>
                      </Select>
                    </div>
                  </div>
                </>
              )}
              {this.state.Multiple && (
                <>
                <div className="editor">
                                    <div className="mainfileUpload">
                                        <Dropzone onDrop={this.fileUpload.bind(this)}>
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()}>
                                            <input
                                                {...getInputProps()}
                                                className="file-upload d-none"
                                            />
                                            <div className="file-icon">
                                                <img src={FileUpload} alt="file-upload" />
                                            </div>
                                            <span className={"drop"}>Drop your file to upload</span>
                                            <button className="fileupload">Choose File</button>
                                            <span className="down">Download Sample File</span>
                                            </div>
                                        )}
                                        </Dropzone>
                                    </div>
                                </div>
                </>
              )}
            </div>
            <div className="editorbtn">
              <button
                type="button"
                onClick={this.handleCreateCategoryClose.bind(this)}
              >
                Cancel
              </button>
              <button type="button">CREATE</button>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Category;
