import React, { Component } from "react";
import Add from "./../../../assets/images/add.svg";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import Lefticon from "./../../../assets/images/Lefticon.svg";
import AddNote from "./../../../assets/images/AddNote.svg";
import { Input, Radio, Select, Table, Steps, Popover } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import Modal from "react-responsive-modal";
import FileUpload from "./../../../assets/images/upload.png";
import Dropzone from "react-dropzone";

const { Step } = Steps;
class Location extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createLocation: false,
      Single: true,
      Multiple: false,
      CurrentStep: 0,
      fileName: "",
      fileN: [],
    };
  }
  handleCreateLocationOpen() {
    this.setState({ createLocation: true });
  }
  handleCreateLocationClose() {
    this.setState({ createLocation: false, CurrentStep: 0 });
  }
  start = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  handleRedioButtonChange(check) {
    if (check === "Single") {
      this.setState({
        Single: true,
        Multiple: false,
      });
    } else {
      this.setState({
        Single: false,
        Multiple: true,
      });
    }
  }
  handleChangeStep = (CurrentStep) => {
    this.setState({
      CurrentStep,
    });
  };
  handleNextStepClick(check) {
    if (check === "next") {
      if (this.state.CurrentStep === 0) {
        this.setState({
          CurrentStep: 1,
        });
      } else {
        this.setState({
          CurrentStep: 2,
        });
      }
    } else {
      if (this.state.CurrentStep === 1) {
        this.setState({
          CurrentStep: 0,
        });
      } else {
        this.setState({
          CurrentStep: 1,
        });
      }
    }
  }
  fileUpload = (e) => {
    var allFiles = [];
    var selectedFiles = e;
    if (selectedFiles) {
      allFiles.push(selectedFiles[0]);

      this.setState({
        fileN: allFiles,
        fileName: allFiles[0].name,
      });
    }
  };
  render() {
    const data = [
      {
        key: "1",
        locationName: "Jr.design engineer",
        code: "HOD",
        brandName: "Vikash Sharma",
        city: "Vikash Sharma",
        state: "Vikash Sharma",
        pinCode: "Vikash Sharma",
      },
      {
        key: "2",
        locationName: "Team Lead",
        code: "Asst Manager",
        brandName: "Vikash Sharma",
        city: "Vikash Sharma",
        state: "Vikash Sharma",
        pinCode: "Vikash Sharma",
      },
      {
        key: "3",
        locationName: "Supervisor",
        code: "Asst Manager",
        brandName: "Vikash Sharma",
        city: "Vikash Sharma",
        state: "Vikash Sharma",
        pinCode: "Vikash Sharma",
      },
    ];
    const { Option } = Select;
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      fixed: "left",
      width: 220,
    };
    const customDot = (dot, { status, index }) => (
      <Popover
        content={
          <span>
            step {index} status: {status}
          </span>
        }
      >
        {dot}
      </Popover>
    );
    return (
      <div className="LocationMain">
        <div className="TasType">
          <div className="Hierdf">
            <div className="hierleft">
              <h4>
                Location
                <img
                  src={Add}
                  alt="add"
                  onClick={this.handleCreateLocationOpen.bind(this)}
                />
              </h4>
            </div>
            <div className="hierright">
              <Input
                placeholder="Search by name, city, state..."
                prefix={<SearchOutlined />}
              />
            </div>
          </div>
          {/* <div className="hierdown">
                        <label><img src={CloseCircle} alt="Delete" />Delete</label>
                        <label><img src={Download} alt="Delete" />Excel</label>
                    </div> */}
          <div className="LocationTable">
            <Table
              rowSelection={rowSelection}
              columns={[
                {
                  title: "Location Name",
                  dataIndex: "locationName",
                  key: "locationName",
                  width: 150,
                },
                {
                  title: "Code",
                  dataIndex: "code",
                  key: "code",
                },
                {
                  title: "Brand Name",
                  key: "brandName",
                  dataIndex: "brandName",
                },
                {
                  title: "City",
                  key: "city",
                  dataIndex: "city",
                },
                {
                  title: "State",
                  key: "state",
                  dataIndex: "state",
                },
                {
                  title: "Pincode",
                  key: "pinCode",
                  dataIndex: "pinCode",
                },
                {
                  title: "Status",
                  key: "status",
                  dataIndex: "status",
                  render: (row, item) => {
                    return (
                      <div className="Status">
                        <Select defaultValue="Active">
                          <Option value="Active">Active</Option>
                          <Option value="Inactive">Inactive</Option>
                        </Select>
                      </div>
                    );
                  },
                },
                {
                  title: "Actions",
                  key: "actions",
                  dataIndex: "actions",
                  fixed: "right",
                  width: 100,
                  render: (row, item) => {
                    return (
                      <div className="Actionic">
                        <img
                          src={AddNote}
                          alt="Edit"
                          onClick={this.handleCreateLocationOpen.bind(this)}
                        />
                        <img src={CloseCircle} alt="delete" />
                      </div>
                    );
                  },
                },
              ]}
              dataSource={data}
              pagination={false}
              scroll={{ x: 1300 }}
            />
          </div>
          {/* Create Location */}
          <Modal
            open={this.state.createLocation}
            onClose={this.handleCreateLocationClose.bind(this)}
            modalId="CreateLocationModal"
            overlayId="overlay"
          >
            <div>
              <div className="createLocation">
                {this.state.CurrentStep !== 0 ? (
                  <img src={Lefticon} alt="Lefticon" className="previousBtn" onClick={this.handleNextStepClick.bind(this, "previous")} />
                ) : null}
                <div className="Steep">
                  <Steps
                    current={this.state.CurrentStep}
                    progressDot={customDot}
                    onChange={this.handleChangeStep}
                  >
                    <Step title="Brand Mapping" />
                    <Step title="Address" />
                    <Step title="Contact Details" />
                  </Steps>
                </div>
                <div className="hier mb-3 mt-4">
                  <label>Add Location</label>
                </div>
                <div className="mb-2">
                  <Radio
                    type="radio"
                    name="Single"
                    checked={this.state.Single}
                    onChange={this.handleRedioButtonChange.bind(this, "Single")}
                  >
                    Single
                  </Radio>
                  <Radio
                    type="radio"
                    name="Single"
                    checked={this.state.Multiple}
                    onChange={this.handleRedioButtonChange.bind(
                      this,
                      "Multiple"
                    )}
                  >
                    Multiple
                  </Radio>
                </div>
                {this.state.Single && (
                  <>
                    {this.state.CurrentStep === 0 && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">Brand</label>
                            <Select defaultValue="Click to Select">
                              <Option value="jackjoes">
                                Jack n Joes and 2 more
                              </Option>
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">Location Code</label>
                            <input type="text" placeholder="Location Code" />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">Location Name</label>
                            <input type="text" placeholder="Location Name" />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">Location Type</label>
                            <Select defaultValue="Click to Select">
                              <Option value="jackjoes">India</Option>
                            </Select>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.CurrentStep === 1 && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">State</label>
                            <Select defaultValue="Click to Select">
                              <Option value="guj">Gujarat</Option>
                            </Select>
                          </div>
                          <div className="col-12 col-md-6">
                            <label className="desig">City</label>
                            <Select defaultValue="Click to Select">
                              <Option value="ahm">Ahmedabad</Option>
                            </Select>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">Address</label>
                            <textarea cols="31" rows="3"></textarea>
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-6">
                            <label className="desig">Region</label>
                            <Select defaultValue="Click to Select">
                              <Option value="ahm">East</Option>
                            </Select>
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.CurrentStep === 2 && (
                      <div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">
                              Email Address
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Email Address"
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12 col-md-12">
                            <label className="desig">Contact Number</label>
                            <input
                              type="text"
                              placeholder="Enter Contact Number"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {this.state.Multiple && (
                  <>
                    <div className="editor">
                      <div className="mainfileUpload">
                        <Dropzone onDrop={this.fileUpload.bind(this)}>
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <input
                                {...getInputProps()}
                                className="file-upload d-none"
                              />
                              <div className="file-icon">
                                <img src={FileUpload} alt="file-upload" />
                              </div>
                              <span className={"drop"}>Drop your file to upload</span>
                              <button className="fileupload">Choose File</button>
                              <span className="down">Download Sample File</span>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="editorbtn">
                <button type="button" onClick={this.handleCreateLocationClose.bind(this)}>Cancel</button>
                {this.state.CurrentStep === 2 ? (
                  <button type="button">Add</button>
                ) : (
                    <button type="button" onClick={this.handleNextStepClick.bind(this, "next")}>Next</button>
                  )}
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default Location;
