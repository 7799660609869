import React, { Component } from "react";
import {
  Table,
  Menu,
  Dropdown,
  Select,
  Popover,
  Radio,
  Tabs,
  DatePicker,
  TimePicker,
} from "antd";
import Profile from "./../../../assets/images/Profile.jpg";
import dropdown from "./../../../assets/images/dropdown.png";
import Search from "./../../../assets/images/Search.svg";
import Add from "./../../../assets/images/add.svg";
import TopDown from "./../../../assets/images/topdown.svg";
import Download from "./../../../assets/images/Download.svg";
import Filter from "./../../../assets/images/Filter.svg";
import AddNote from "./../../../assets/images/AddNote.svg";
import Opti from "./../../../assets/images/optionn.svg";
import "./../../../assets/css/Leads/leadlist.css";
import FileUpload from "./../../../assets/images/upload.png";
import Lead from "./../../../assets/images/Lead.svg";
import Call from "./../../../assets/images/CallBlue.svg";
import Meeting from "./../../../assets/images/MeetingBlue.svg";
import ToDo from "./../../../assets/images/TodoBlue.svg";
import Referal from "./../../../assets/images/ReferalBlue.svg";
import BlockService from "./../../../assets/images/BlockBlue.svg";
import ListMobile from "./../../../assets/images/list.png";
import GridMobile from "./../../../assets/images/grid.png";
// import BlockService from "./../../../assets/images/BlockBlue.svg";
import DownMobile from "./../../../assets/images/CircleBack.svg";
import Modal from "react-responsive-modal";
import moment from "moment";
import CKEditor from "ckeditor4-react";
import Dropzone from "react-dropzone";

class LeadList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFilter: false,
      addNote: false,
      addTask: false,
      BulkUpload: false,
      createTask: false,
      listview: true,
      cardView: false,
      isPophoverVisibale: false,
      fileName: "",
      fileN: [],
      isMobileView: false,
      isBottom: false
    };
  }
  handleAddNotesOpen() {
    this.setState({ addNote: true, isPophoverVisibale: true });
  }
  handleAddNotesClose() {
    this.setState({ addNote: false, isPophoverVisibale: false });
  }
  handleAddTaskOpen() {
    this.setState({ addTask: true });
  }
  handleAddTaskClose() {
    this.setState({ addTask: false });
  }
  handleBulkUploadOpen() {
    this.setState({ BulkUpload: true });
  }
  handleBulkUploadClose() {
    this.setState({ BulkUpload: false });
  }
  handleCreateTaskOpen() {
    this.setState({ createTask: true, isPophoverVisibale: true });
  }
  handleCreateTaskClose() {
    this.setState({ createTask: false, isPophoverVisibale: false });
  }
  handleFilterClick = () => {
    this.setState({ isFilter: !this.state.isFilter });
  };
  handleBottomClick = () => {
    this.setState({ isBottom: !this.state.isBottom });
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  resize() {
    if (window.innerWidth <= 767) {
      this.setState({ isMobileView: true });
    } else {
      this.setState({ isMobileView: false });
    }
  }
  start = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  handleRedioButtonChange(check) {
    if (check === "listview") {
      this.setState({
        listview: true,
        cardView: false,
      });
    } else {
      this.setState({
        listview: false,
        cardView: true,
      });
    }
  }
  fileUpload = (e) => {
    var allFiles = [];
    var selectedFiles = e;
    if (selectedFiles) {
      allFiles.push(selectedFiles[0]);

      this.setState({
        fileN: allFiles,
        fileName: allFiles[0].name,
      });
    }
  };
  render() {
    const StatusDropdown = (
      <Menu>
        <div className="dropdown">
          <input type="search" className="sear" placeholder="Search Comment" />
          <img src={Search} alt="Search" />
          <div className="dropdownscroll">
            <div className="rad">
              <Radio>Junk Lead</Radio>
            </div>
            <div className="rad">
              <Radio>Appointment fixed by C C</Radio>
            </div>
            <div className="rad">
              <Radio>Appointment fixed by U nit</Radio>
            </div>
            <div className="rad">
              <Radio>CC Outbound followup</Radio>
            </div>
            <div className="rad">
              <Radio>Complaint</Radio>
            </div>
            <div className="rad">
              <Radio>Junk Lead</Radio>
            </div>
            <div className="rad">
              <Radio>Appointment fixed by C C</Radio>
            </div>
            <div className="rad">
              <Radio>Appointment fixed by U nit</Radio>
            </div>
            <div className="rad">
              <Radio>CC Outbound followup</Radio>
            </div>
            <div className="rad">
              <Radio>Complaint</Radio>
            </div>
          </div>
        </div>
      </Menu>
    );
    const OptionDropdown = (
      <Menu>
        <div className="Opdropdown">
          <div className="dropdownscroll">
            <div className="rad1">
              <Radio>Only Query Lead</Radio>
            </div>
            <div className="rad1">
              <Radio>Follow Up</Radio>
            </div>
            <div className="rad1">
              <Radio>Disqualify</Radio>
            </div>
            <div className="rad1">
              <Radio>View Timelines</Radio>
            </div>
            <div className="rad1">
              <Radio>Add Notes</Radio>
            </div>
            <div className="rad1">
              <Radio>Add Task</Radio>
            </div>
            <div className="rad1">
              <Radio>Email</Radio>
            </div>
            <div className="rad1">
              <Radio>SMS</Radio>
            </div>
          </div>
        </div>
      </Menu>
    );

    const data = [
      {
        key: "1",
        contact: (
          <div className="Cate1">
            <label>
              +91 9873470074
              <span>Offline Store</span>
            </label>
          </div>
        ),
      },
      {
        key: "2",
        contact: (
          <div className="Cate1">
            <label>
              +91 9873470074
              <span>Offline Store</span>
            </label>
          </div>
        ),
      },
      {
        key: "3",
        contact: (
          <div className="Cate1">
            <label>
              +91 9873470074
              <span>Offline Store</span>
            </label>
          </div>
        ),
      },
      {
        key: "4",
        contact: (
          <div className="Cate1">
            <label>
              +91 9873470074
              <span>Offline Store</span>
            </label>
          </div>
        ),
      },
      {
        key: "5",
        contact: (
          <div className="Cate1">
            <label>
              +91 9873470074
              <span>Offline Store</span>
            </label>
          </div>
        ),
      },
      {
        key: "6",
        contact: (
          <div className="Cate1">
            <label>
              +91 9873470074
              <span>Offline Store</span>
            </label>
          </div>
        ),
      },
    ];
    const { Option } = Select;
    const { TabPane } = Tabs;
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
    // const hasSelected = selectedRowKeys.length > 0;
    return (
      <div>
      <div className="leadsmain">
        <div className="lead">
          <div className="leftleft">
            <h4>
              Leads
              <img
                src={Add}
                alt="add"
                onClick={this.handleAddTaskOpen.bind(this)}
              />
              <span>
                Use this area, if you want to show some hints or additional
                information
              </span>
            </h4>
          </div>
          <img src={Filter} alt="Filter" className="Fillte" onClick={this.handleBottomClick.bind(this)} />
          <ul className="leadright">
            <li>
              <div className="sele">
                <Select defaultValue="liView">
                  <Option value="caView">Card View</Option>
                  <Option value="liView">List View</Option>
                  <Option value="nrView">Normal View</Option>
                </Select>
              </div>
            </li>
            <li>
              <div className="rad">
                <label>
                  <input
                    type="radio"
                    name="light"
                    checked={this.state.listview}
                    onChange={this.handleRedioButtonChange.bind(
                      this,
                      "listview"
                    )}
                  />
                  <span className="design"></span>
                  <span className="text">List View</span>
                </label>
              </div>
            </li>
            <li>
              <div className="rad">
                <label>
                  <input
                    type="radio"
                    name="light"
                    checked={this.state.cardView}
                    onChange={this.handleRedioButtonChange.bind(
                      this,
                      "cardview"
                    )}
                  />
                  <span className="design"></span>
                  <span className="text">Card View</span>
                </label>
              </div>
            </li>
            <li>
              <img src={TopDown} alt="TopDown" />
            </li>
            <li>
              <img src={Search} alt="Search" />
            </li>
            <li>
              <img
                src={Filter}
                alt="Filter"
                onClick={this.handleFilterClick.bind(this)}
              />
            </li>
            <li>
              <img src={Download} alt="Download" />
            </li>
          </ul>
        </div>
        {this.state.isFilter ? (
          <div className="row m-0 mt-3">
            <div className="col-md-12 filter">
              <label>Filter</label>
            </div>
          </div>
        ) : null}
        {this.state.listview && (
          <div className="LeadTable">
            <Table
              rowSelection={rowSelection}
              columns={[
                {
                  render: (row, item) => {
                    return (
                      <div>
                        <Dropdown overlay={OptionDropdown} trigger={["click"]}>
                          <img src={Opti} alt="Option" className="Option" />
                        </Dropdown>
                      </div>
                    );
                  },
                },
                {
                  title: "Name & Email",
                  dataIndex: "name",
                  key: "name",
                  render: (row, item) => {
                    return (
                      <div className="Cate2">
                        <Popover
                          content={
                            <div className="UserInfo">
                              <div className="usname">
                                <div className="usname1">
                                  <label>Naman Rampal</label>
                                  <label>1234567890</label>
                                </div>
                                <div className="usname1">
                                  <label>Last update</label>
                                  <label style={{ fontStyle: "italic" }}>
                                    2 Days ago
                                  </label>
                                </div>
                              </div>
                              <div className="usnam">
                                <div className="usnam1">
                                  <label>Mobile</label>:
                                  <label>+91 1234567890</label>
                                </div>
                                <div className="usnam1">
                                  <label>Email</label>:
                                  <label>easyrewards@gmail.com</label>
                                </div>
                                <div className="usnam1">
                                  <label>Status</label>:<label>Active</label>
                                </div>
                                <div className="usnam1">
                                  <label>Assign To</label>:
                                  <label>Jahn Xyz</label>
                                </div>
                                <div className="usnam1">
                                  <label>Unit</label>:<label>5</label>
                                </div>
                              </div>
                              <div className="usnamebtn">
                                <label
                                  onClick={this.handleCreateTaskOpen.bind(this)}
                                >
                                  <img src={AddNote} alt="CreateTask" />
                                  Create Task
                                </label>
                                <label
                                  onClick={this.handleAddNotesOpen.bind(this)}
                                >
                                  <img src={AddNote} alt="AddNote" />
                                  Write Note
                                </label>
                              </div>
                            </div>
                          }
                          placement="bottomLeft"
                          trigger="click"
                          overlayClassName={
                            this.state.isPophoverVisibale
                              ? "isvisibalePophover"
                              : ""
                          }
                          // overlayClassName="isvisibalePophover"
                        >
                          <img src={Profile} alt="NameIcon" />
                        </Popover>
                        <label>
                          Naman Rampal
                          <span>naman.kreative@gmail.com</span>
                        </label>
                      </div>
                    );
                  },
                },
                {
                  title: "Contact & Source",
                  dataIndex: "contact",
                  key: "contact",
                  className:"MobViewNone",
                },
                {
                  title: "Category",
                  key: "category",
                  dataIndex: "category",
                  className:"MobViewNone",
                  render: (row, item) => {
                    return (
                      <div className="Cate">
                        <label>Category 1</label>
                      </div>
                    );
                  },
                },
                {
                  title: "Sub Category",
                  key: "subcategory",
                  dataIndex: "subcategory",
                  className:"MobViewNone",
                  render: (row, item) => {
                    return (
                      <div className="Cate">
                        <label>12 June</label>
                      </div>
                    );
                  },
                },
                {
                  title: "Source",
                  key: "source",
                  dataIndex: "source",
                  className:"MobViewNone",
                  render: (row, item) => {
                    return (
                      <div className="Cate">
                        <label>Campaign</label>
                      </div>
                    );
                  },
                },
                {
                  title: "Status",
                  key: "status",
                  dataIndex: "status",
                  className:"MobViewNone",
                  render: (row, item) => {
                    return (
                      <div className="Cate3">
                        <label>Qualified</label>
                        <Dropdown overlay={StatusDropdown} trigger={["click"]}>
                          <img src={dropdown} alt="Dropdown" />
                        </Dropdown>
                      </div>
                    );
                  },
                },
                {
                  title: "Sub Category",
                  key: "subCat",
                  dataIndex: "subCat",
                  className:"MobViewNone",
                  render: (row, item) => {
                    return (
                      <div className="Cate">
                        <label>Intrest</label>
                      </div>
                    );
                  },
                },
              ]}
              expandedRowRender={(row) => {
                return (
                  <div className="ExPandRow">
                    <div className="row m-0 mb-2">
                      <div className="col-6 col-md-6">
                          <label className="Conat">Contact & source</label>
                          <label className="Conat1">+91 9873470074</label>
                          <label className="Conat1">OfflineStore</label>
                      </div>
                      <div className="col-6 col-md-6">
                          <label className="Conat">Category</label>
                          <label className="Conat1">Category 1</label>
                      </div>
                    </div>
                    <div className="row m-0 mb-2">
                      <div className="col-6 col-md-6">
                        <label className="Conat">Sub Category</label>
                        <label className="Conat1">12 june</label>
                      </div>
                      <div className="col-6 col-md-6">
                        <label className="Conat">Source</label>
                        <label className="Conat1">Category 1</label>
                      </div>
                    </div>
                    <div className="row m-0 mb-2">
                      <div className="col-6 col-md-6">
                        <label className="Conat">Status</label>
                        <label className="Conat12">Qualified</label>
                        <Dropdown overlay={StatusDropdown} trigger={["click"]}>
                          <img src={dropdown} alt="Dropdown" className="DropStatusimg" />
                        </Dropdown>
                      </div>
                      <div className="col-6 col-md-6">
                        <label className="Conat">Sub Category</label>
                        <label className="Conat1">Intrest</label>
                      </div>
                    </div>
                  </div>
                )}
              }
              expandIcon={({ expanded, onExpand, record }) =>
                expanded ? (
                  <div className="expandown1">
                    <img src={DownMobile} onClick={(e) => onExpand(record, e)} />
                  </div>
                ) : (
                    <div className="expandown">
                      <img src={DownMobile} onClick={(e) => onExpand(record, e)} />
                    </div>
                  )
              }
              expandIconColumnIndex={this.state.isMobileView ? 8 : -1}
              expandIconAsCell={false}
              dataSource={data}
              pagination={false}
            />
          </div>
        )}
        {this.state.cardView && (
          <div className="row m-0 ">
            <div className="cards">
              <label className="leadid">Lead ID - 123456</label>
              <div className="leada">
                <label className="leadate">
                  Lead Created Date - 14th Dec 2020
                </label>
                <label className="leadate1">
                  Updated By - Vikas, 14 Dec 2020 12:59 PM
                </label>
                <label className="leadate2">Junk lead</label>
              </div>
              <div className="cads">
                <div className="card">
                  <label className="custinfo">Customer Info</label>
                  <div className="usnam1">
                    <label>Name</label>:<label>Adaikalam</label>
                  </div>
                  <div className="usnam1">
                    <label>Mobile</label>:<label>+91 9898989898</label>
                  </div>
                  <div className="usnam1">
                    <label>Age</label>:<label>40</label>
                  </div>
                  <div className="usnam1">
                    <label>Email</label>:<label>Karthiswaran@gmail.com</label>
                  </div>
                  <div className="usnam1">
                    <label>Address</label>:<label>Bali</label>
                  </div>
                </div>
                <div className="card">
                  <label className="custinfo">Lead Info</label>
                  <div className="usnam">
                    <label>Enquiry Type</label>:<label>Campaign</label>
                  </div>
                  <div className="usnam">
                    <label>Enquiry Sub Type</label>:<label>IVF</label>
                  </div>
                  <div className="usnam">
                    <label>Enquiry Source</label>:<label>Facebook</label>
                  </div>
                  <div className="usnam">
                    <label>Page/Form Name</label>:
                    <label>IVF Video Consultation Trichy Tamil</label>
                  </div>
                  <div className="usnam">
                    <label>Sub Status</label>:
                    <label>Wrongly Submitted / Clicked / Called</label>
                  </div>
                  <div className="usnam">
                    <label>Assign To</label>:<label>Trichy</label>
                  </div>
                  <div className="usnam">
                    <label>Notes</label>:<label>Wrongly enquiry</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Add Note Modal */}
        <Modal
          open={this.state.addNote}
          onClose={this.handleAddNotesClose.bind(this)}
          modalId="AddNotesModal"
          overlayId="overlay"
        >
          <div className="addnote">
            <label>Add a note</label>
            <CKEditor
              config={{
                toolbar: [
                  {
                    name: "basicstyles",
                    items: ["Bold", "Italic", "Strike"],
                  },
                  {
                    name: "styles",
                    items: ["Styles", "Format"],
                  },
                  {
                    name: "paragraph",
                    items: ["NumberedList", "BulletedList"],
                  },
                  {
                    name: "links",
                    items: ["Link", "Unlink"],
                  },
                  {
                    name: "insert",
                    items: ["Image", "Table"],
                  },
                  {
                    name: "tools",
                    items: ["Maximize"],
                  },
                  {
                    name: "editing",
                    items: ["Scayt"],
                  },
                ],
              }}
            />
            <div className="editorbtn">
              <button type="button">update</button>
              <button
                type="button"
                onClick={this.handleAddNotesClose.bind(this)}
              >
                Cancel
              </button>
            </div>
          </div>
        </Modal>
        {/* Add Lead Task Modal */}
        <Modal
          open={this.state.addTask}
          onClose={this.handleAddTaskClose.bind(this)}
          modalId="AddLeadTaskModal"
          overlayId="overlay"
        >
        {this.state.isMobileView ? (
          <div className="addlead">
            <Tabs tabPosition="top">
              <TabPane tab={<div>Customer Info</div>} key="1">
                <div className="customertab">
                  <div className="bulk mb-3">
                    <label>Customer Info</label>
                    <label onClick={this.handleBulkUploadOpen.bind(this)}>
                      Bulk Upload
                    </label>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Mobile Number</label>
                      <input
                        type="number"
                        className="mobileinput"
                        placeholder="9898989898"
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Alternate Mobile Number</label>
                      <input
                        type="number"
                        className="mobileinput"
                        placeholder="9898989898"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-4 col-md-4">
                      <label className="mobile">First Name</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Michael"
                      />
                    </div>
                    <div className="col-12 col-sm-4 col-md-4">
                      <label className="mobile">Middle Name</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Gary"
                      />
                    </div>
                    <div className="col-12 col-sm-4 col-md-4">
                      <label className="mobile">Last Name</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Scott"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Email</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="valeriewwalton@linkedin.com"
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Alternate Email</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="michael@linkedin.com"
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-12 col-md-12">
                      <label className="mobile mb-1">Gender</label>
                      <Radio>Male</Radio>
                      <Radio>Female</Radio>
                      <Radio>Other</Radio>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Date of Birth</label>
                      <DatePicker
                        className="mobileinput"
                        placeholder="Date of Birth"
                      />
                    </div>
                    <div className="col-12 col-sm-2 col-md-2">
                      <label className="mobile">Age</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Years"
                      />
                    </div>
                    <div className="col-12 col-sm-2 col-md-2">
                      <label style={{ visibility: "hidden" }}>Age</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Months"
                      />
                    </div>
                    <div className="col-12 col-sm-2 col-md-2">
                      <label style={{ visibility: "hidden" }}>Age</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Days"
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-12 col-md-12">
                      <label className="mobile">Address</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="L 1103 KDP Grand Savana"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">City</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Ghaziabad"
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">State</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Pradesh"
                      />
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12 col-sm-6 col-md-6"></div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <div className="casane">
                        <button
                          type="button"
                          onClick={this.handleAddTaskClose.bind(this)}
                        >
                          Cancel
                        </button>
                        <button type="button">Save & New</button>
                        <button type="button">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tab={<div>Lead Detail</div>} key="2">
                <div className="customertab">
                  <div className="bulk mb-3">
                    <label>Lead Detail</label>
                    <label onClick={this.handleBulkUploadOpen.bind(this)}>
                      Bulk Upload
                    </label>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Source</label>
                      <Select defaultValue="lucy" className="">
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                      </Select>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Type</label>
                      <Select defaultValue="lucy" className="">
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Lead Category</label>
                      <Select defaultValue="lucy" className="">
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                      </Select>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Sub Category</label>
                      <Select defaultValue="lucy" className="">
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">LMD</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Calculate LMD"
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">EDD</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Calculate EDD"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Unit</label>
                      <Select defaultValue="lucy" className="">
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                      </Select>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Doctor</label>
                      <Select defaultValue="lucy" className="">
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Status</label>
                      <Select defaultValue="lucy" className="">
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                      </Select>
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Sub Status</label>
                      <Select defaultValue="lucy" className="">
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <label className="mobile">Assign To</label>
                      <Select defaultValue="lucy" className="">
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <label className="mobile">Editor</label>
                      <CKEditor
                        config={{
                          toolbar: [
                            {
                              name: "basicstyles",
                              items: ["Bold", "Italic", "Strike"],
                            },
                            {
                              name: "styles",
                              items: ["Styles", "Format"],
                            },
                            {
                              name: "paragraph",
                              items: ["NumberedList", "BulletedList"],
                            },
                            {
                              name: "links",
                              items: ["Link", "Unlink"],
                            },
                            {
                              name: "insert",
                              items: ["Image", "Table"],
                            },
                            {
                              name: "tools",
                              items: ["Maximize"],
                            },
                            {
                              name: "editing",
                              items: ["Scayt"],
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-12 col-md-6"></div>
                    <div className="col-12 col-md-6">
                      <div className="casane">
                        <button
                          type="button"
                          onClick={this.handleAddTaskClose.bind(this)}
                        >
                          Cancel
                        </button>
                        <button type="button">Save & New</button>
                        <button type="button">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tab={<div>Leads Additional Details</div>} key="3">
                <div className="customertab">
                  <div className="bulk mb-3">
                    <label>Leads Additional Details</label>
                    <label onClick={this.handleBulkUploadOpen.bind(this)}>
                      Bulk Upload
                    </label>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Gestational Age</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Gestational Age"
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Insurance Provider # 1</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Provider # 1"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Insurance Policy No.</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Policy No."
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Preauthorization Code</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Preauthorization Code"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">
                        Preferred Date & Time to Contact
                      </label>
                      <DatePicker
                        className="mobileinput"
                        placeholder="Select Date"
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Customer Income</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Customer Income"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Occupation</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Occupation"
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Additional Income</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Additional Income"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">HNI</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter HNI"
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Enquiry By</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Enquiry By"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">TPA</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter TPA"
                      />
                    </div>
                    <div className="col-12 col-sm-6 col-md-6">
                      <label className="mobile">Page/Form Name</label>
                      <Select defaultValue="lucy">
                        <Option value="lead">Lead</Option>
                        <Option value="details">Lead Details</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-12  col-md-6"></div>
                    <div className="col-12 col-md-6">
                      <div className="casane">
                        <button
                          type="button"
                          onClick={this.handleAddTaskClose.bind(this)}
                        >
                          Cancel
                        </button>
                        <button type="button">Save & New</button>
                        <button type="button">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        ):
        <div className="addlead">
            <Tabs tabPosition="left">
              <TabPane tab={<div>Customer Info</div>} key="1">
                <div className="customertab">
                  <div className="bulk mb-3">
                    <label>Customer Info</label>
                    <label onClick={this.handleBulkUploadOpen.bind(this)}>
                      Bulk Upload
                    </label>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Mobile Number</label>
                      <input
                        type="number"
                        className="mobileinput"
                        placeholder="9898989898"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Alternate Mobile Number</label>
                      <input
                        type="number"
                        className="mobileinput"
                        placeholder="9898989898"
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-4">
                      <label className="mobile">First Name</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Michael"
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <label className="mobile">Middle Name</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Gary"
                      />
                    </div>
                    <div className="col-12 col-md-4">
                      <label className="mobile">Last Name</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Scott"
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Email</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="valeriewwalton@linkedin.com"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Alternate Email</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="michael@linkedin.com"
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-12">
                      <label className="mobile mb-1">Gender</label>
                      <Radio>Male</Radio>
                      <Radio>Female</Radio>
                      <Radio>Other</Radio>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Date of Birth</label>
                      <DatePicker
                        className="mobileinput"
                        placeholder="Date of Birth"
                      />
                    </div>
                    <div className="col-12 col-md-2">
                      <label className="mobile">Age</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Years"
                      />
                    </div>
                    <div className="col-12 col-md-2">
                      <label style={{ visibility: "hidden" }}>Age</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Months"
                      />
                    </div>
                    <div className="col-12 col-md-2">
                      <label style={{ visibility: "hidden" }}>Age</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Days"
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-12">
                      <label className="mobile">Address</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="L 1103 KDP Grand Savana"
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">City</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Ghaziabad"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">State</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Pradesh"
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-12 col-md-6"></div>
                    <div className="col-12 col-md-6">
                      <div className="casane">
                        <button
                          type="button"
                          onClick={this.handleAddTaskClose.bind(this)}
                        >
                          Cancel
                        </button>
                        <button type="button">Save & New</button>
                        <button type="button">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tab={<div>Lead Detail</div>} key="2">
                <div className="customertab">
                  <div className="bulk mb-3">
                    <label>Lead Detail</label>
                    <label onClick={this.handleBulkUploadOpen.bind(this)}>
                      Bulk Upload
                    </label>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Source</label>
                      <Select defaultValue="lucy" className="">
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                      </Select>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Type</label>
                      <Select defaultValue="lucy" className="">
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Lead Category</label>
                      <Select defaultValue="lucy" className="">
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                      </Select>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Sub Category</label>
                      <Select defaultValue="lucy" className="">
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">LMD</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Calculate LMD"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">EDD</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Calculate EDD"
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Unit</label>
                      <Select defaultValue="lucy" className="">
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                      </Select>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Doctor</label>
                      <Select defaultValue="lucy" className="">
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Status</label>
                      <Select defaultValue="lucy" className="">
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                      </Select>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Sub Status</label>
                      <Select defaultValue="lucy" className="">
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-12">
                      <label className="mobile">Assign To</label>
                      <Select defaultValue="lucy" className="">
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-12">
                      <label className="mobile">Editor</label>
                      <CKEditor
                        config={{
                          toolbar: [
                            {
                              name: "basicstyles",
                              items: ["Bold", "Italic", "Strike"],
                            },
                            {
                              name: "styles",
                              items: ["Styles", "Format"],
                            },
                            {
                              name: "paragraph",
                              items: ["NumberedList", "BulletedList"],
                            },
                            {
                              name: "links",
                              items: ["Link", "Unlink"],
                            },
                            {
                              name: "insert",
                              items: ["Image", "Table"],
                            },
                            {
                              name: "tools",
                              items: ["Maximize"],
                            },
                            {
                              name: "editing",
                              items: ["Scayt"],
                            },
                          ],
                        }}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-12 col-md-6"></div>
                    <div className="col-12 col-md-6">
                      <div className="casane">
                        <button
                          type="button"
                          onClick={this.handleAddTaskClose.bind(this)}
                        >
                          Cancel
                        </button>
                        <button type="button">Save & New</button>
                        <button type="button">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tab={<div>Leads Additional Details</div>} key="3">
                <div className="customertab">
                  <div className="bulk mb-3">
                    <label>Leads Additional Details</label>
                    <label onClick={this.handleBulkUploadOpen.bind(this)}>
                      Bulk Upload
                    </label>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Gestational Age</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Gestational Age"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Insurance Provider # 1</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Provider # 1"
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Insurance Policy No.</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Policy No."
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Preauthorization Code</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Preauthorization Code"
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">
                        Preferred Date & Time to Contact
                      </label>
                      <DatePicker
                        className="mobileinput"
                        placeholder="Select Date"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Customer Income</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Customer Income"
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">Occupation</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Occupation"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Additional Income</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Additional Income"
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">HNI</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter HNI"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Enquiry By</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter Enquiry By"
                      />
                    </div>
                  </div>
                  <div className="row mb-4">
                    <div className="col-12 col-md-6">
                      <label className="mobile">TPA</label>
                      <input
                        type="text"
                        className="mobileinput"
                        placeholder="Enter TPA"
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="mobile">Page/Form Name</label>
                      <Select defaultValue="lucy">
                        <Option value="lead">Lead</Option>
                        <Option value="details">Lead Details</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-12 col-md-6"></div>
                    <div className="col-12 col-md-6">
                      <div className="casane">
                        <button
                          type="button"
                          onClick={this.handleAddTaskClose.bind(this)}
                        >
                          Cancel
                        </button>
                        <button type="button">Save & New</button>
                        <button type="button">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            </Tabs>
        </div>
        }
        </Modal>
        {/* Bulk Upload Modal */}
        <Modal
          open={this.state.BulkUpload}
          onClose={this.handleBulkUploadClose.bind(this)}
          modalId="BulkUploadModal"
          overlayId="overlay"
        >
          <div className="addnote">
            <div className="bul">
              <label>Upload Bulk Leads</label>
              <label>Single Upload</label>
            </div>
            <div className="editor">
              <div className="mainfileUpload">
                <Dropzone onDrop={this.fileUpload.bind(this)}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input
                        {...getInputProps()}
                        className="file-upload d-none"
                      />
                      <div className="file-icon">
                        <img src={FileUpload} alt="file-upload" />
                      </div>
                      <span className={"drop"}>Drop your file to upload</span>
                      <button className="fileupload">Choose File</button>
                      <span className="down">Download Sample File</span>
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
            <div className="editorbtn">
              <button
                type="button"
                onClick={this.handleBulkUploadClose.bind(this)}
              >
                Cancel
              </button>
              <button type="button">Save</button>
            </div>
          </div>
        </Modal>
        {/* Create Task Modal */}
        <Modal
          open={this.state.createTask}
          onClose={this.handleCreateTaskClose.bind(this)}
          modalId="AddCreateTaskModal"
          overlayId="overlay"
        >
          <div className="addtas">
            <div className="customertab">
              <div className="bulk mb-3">
                <label>Add Taks</label>
              </div>
              <div className="row mb-4">
                <div className="col-12 col-md-6">
                  <label className="mobile">Lead Status</label>
                  <Select defaultValue="Active" className="">
                    <Option value="Active">Jack</Option>
                    <Option value="Inactive">Lucy</Option>
                  </Select>
                </div>
                <div className="col-12 col-md-6">
                  <label className="mobile">Sub Status</label>
                  <Select defaultValue="Active" className="">
                    <Option value="Active">Active</Option>
                    <Option value="Inactive">Inactive</Option>
                  </Select>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 col-md-12">
                  <label className="mobile">Activity</label>
                  <div className="acti">
                    <Radio.Group defaultValue="a" size="large">
                      <Radio.Button value="a">
                        <img src={Call} />
                        Call
                      </Radio.Button>
                      <Radio.Button value="b">
                        <img src={Meeting} />
                        Meeting
                      </Radio.Button>
                      <Radio.Button value="c">
                        <img src={ToDo} />
                        To-do
                      </Radio.Button>
                      <Radio.Button value="d">
                        <img src={Referal} />
                        Referal
                      </Radio.Button>
                      <Radio.Button value="e">
                        <img src={BlockService} />
                        Block For Service
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 col-md-12">
                  <label className="mobile">Title</label>
                  <input
                    type="text"
                    className="mobileinput"
                    placeholder="Demo Call"
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 col-md-4">
                  <label className="mobile">Start Date</label>
                  <DatePicker
                    className="mobileinput"
                    placeholder="Start Date"
                  />
                </div>
                <div className="col-12 col-md-4">
                  <label className="mobile">End Date</label>
                  <DatePicker className="mobileinput" placeholder="End Date" />
                </div>
                <div className="col-12 col-md-4">
                  <label className="mobile">Duration</label>
                  <TimePicker
                    className="mobileinput"
                    defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                  />
                  {/* <input type="number" className="mobileinput" placeholder="00:00:01" /> */}
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 col-md-6">
                  <label className="mobile">Assign To</label>
                  <Select defaultValue="Active" className="">
                    <Option value="Active">Jack</Option>
                    <Option value="Inactive">Lucy</Option>
                  </Select>
                </div>
                <div className="col-12 col-md-6">
                  <label className="mobile">Notify</label>
                  <label className="provide">
                    <img src={Lead} alt="Notify" />
                    Provider
                  </label>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 col-md-12">
                  <CKEditor
                    config={{
                      toolbar: [
                        {
                          name: "basicstyles",
                          items: ["Bold", "Italic", "Strike"],
                        },
                        {
                          name: "styles",
                          items: ["Styles", "Format"],
                        },
                        {
                          name: "paragraph",
                          items: ["NumberedList", "BulletedList"],
                        },
                        {
                          name: "links",
                          items: ["Link", "Unlink"],
                        },
                        {
                          name: "insert",
                          items: ["Image", "Table"],
                        },
                        {
                          name: "tools",
                          items: ["Maximize"],
                        },
                        {
                          name: "editing",
                          items: ["Scayt"],
                        },
                      ],
                    }}
                  />
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 col-md-6">
                  <label className="mobile">Search Comment From Template</label>
                  <div className="Sear">
                    <input type="search" className="mobileinput mr-1" />
                    <Select defaultValue="Active" className="">
                      <Option value="Active">Jack</Option>
                      <Option value="Inactive">Lucy</Option>
                    </Select>
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-12 col-md-6"></div>
                <div className="col-12 col-md-6">
                  <div className="casane">
                    <button type="button">Save</button>
                    <button
                      type="button"
                      onClick={this.handleCreateTaskClose.bind(this)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
        {/* Mobile View Filter */}
        {this.state.isBottom ? (
          <div className="mobbottom">
            <div className="main">
              <div className="item1">
                <div class="background" onClick={this.handleRedioButtonChange.bind(this,"listview")}>
                  <img src={ListMobile} class="campaign-icon" />
                </div>
              </div>
              <div className="item1">
                <div class="background" onClick={this.handleRedioButtonChange.bind(this,"cardview")}>
                  <img src={GridMobile} class="campaign-icon" />
                </div>
              </div>
              <div className="item1">
                <div class="background">
                  <img src={Search} class="campaign-icon" />
                </div>
              </div>
              <div className="item1">
                <div class="background">
                  <img src={TopDown} class="campaign-icon" />
                </div>
              </div>
              <div className="item1">
                <div class="background">
                  <img src={Download} class="campaign-icon" />
                </div>
              </div>
              <div className="item1">
                <div class="background">
                  <img src={Filter} class="campaign-icon" />
                </div>
              </div>
            </div>
        </div>
        ) : null }
    </div>
    );
  }
}

export default LeadList;
