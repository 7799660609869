import React, { Component } from "react";
import LoginLogo from "./../../assets/images/logo.png";
import { Link } from "react-router-dom";
import "./../../assets/css/Login/login.css"

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {}

  componentDidMount() {}

  render() {
    return (
      <div>
        <div className="LoginBack">
          <div className="center" style={{position:"relative"}}>
            <div className="loginlogo">
              <img src={LoginLogo} alt="Logo" />
            </div>
            <div className="mb-3">
              <input type="text" className="email" placeholder="Email ID*" />
            </div>
            <div className="mb-3">
              <button type="button" className="loginbtn">Recover Password</button>
            </div>
            <Link to="Login" className="forpass">Try Login Again</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
