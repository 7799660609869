import React, { Component } from "react";
import PrivateRoute from "./utils/PrivateRoute";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./component/public/Login";
import ProgramCode from "./component/public/ProgramCode";
import ForgotPassword from "./component/public/ForgotPassword";
import Index from "./component/private/Index";
import ChangePassword from "./component/public/ChangePassword";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={ProgramCode} />
            <Route exact path="/Login" component={Login} />
            <Route exact path="/ForgotPassword" component={ForgotPassword} />
            <Route exact path="/ChangePassword" component={ChangePassword} />
            <PrivateRoute>
              <Route path="/" component={Index} />
            </PrivateRoute>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
