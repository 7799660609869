import React, { useEffect, useState } from "react";
import LoginLogo from "./../../assets/images/logo.png";
import { Link, useHistory } from "react-router-dom";
import "./../../assets/css/Login/login.css";

const Login = () => {
  const history = useHistory();
  const handleLoginClick = () => {
    window.localStorage.setItem("ACCESS_TOKEN_NAME", "12345");
    history.push("LeadList");
  };

  return (
    <div>
      <div className="LoginBack">
        <div className="center" style={{ position: "relative" }}>
          <div className="loginlogo">
            <img src={LoginLogo} alt="Logo" />
          </div>
          <div className="mb-3">
            <input type="text" className="email" placeholder="Email ID*" />
          </div>
          <div className="mb-3">
            <input type="password" className="email" placeholder="Password*" />
          </div>
          <div className="mb-3">
            <button
              type="button"
              className="loginbtn"
              onClick={handleLoginClick}
            >
              Login
            </button>
          </div>
          <Link to="ForgotPassword" className="forpass">
            Forgot Password
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Login;
