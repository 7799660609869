import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import LeadList from "./Lead/LeadList";
import MasterLayout from "./HeaderSideMenu/MasterLayout";
import LeadDetails from "./Lead/LeadDetails";
import "./../../assets/css/HeaderSidemenu/headersidemenu.css";
import Hierarchy from "./AdminSetting/Hierarchy";
import Location from "./AdminSetting/Location";
import Opportunity from "./Lead/Opportunity";
import TaskType from "./AdminSetting/TaskType";
import Priority from "./AdminSetting/Priority";
import SpecialityMaster from "./AdminSetting/SpecialityMaster";
import CrmRole from "./AdminSetting/CrmRole";
import Notification from "./AdminSetting/Notification";
import Category from "./AdminSetting/Category";
import Dnd from "./AdminSetting/Dnd";
import LeadStageManage from "./AdminSetting/LeadStageManage";
import SlaMaster from "./AdminSetting/SlaMaster";
import Users from "./AdminSetting/Users";
import LeadScoring from "./AdminSetting/LeadScoring";
import CustomFields from "./AdminSetting/CustomFields";
import Alerts from "./AdminSetting/Alerts";
import Template from "./AdminSetting/Template";
import RoleAccessControl from "./AdminSetting/RoleAccessControl";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    const { match } = this.props;
    return (
      <MasterLayout>
        <Switch>
          <Route exact path={`${match.url}LeadList`} component={LeadList} />
          <Route
            exact
            path={`${match.url}LeadDetails/:LeadCode`}
            component={LeadDetails}
          />
          <Route
            exact
            path={`${match.url}Opportunity`}
            component={Opportunity}
          />
          <Route exact path={`${match.url}hierarchy`} component={Hierarchy} />
          <Route exact path={`${match.url}Location`} component={Location} />
          <Route exact path={`${match.url}TaskType`} component={TaskType} />
          <Route exact path={`${match.url}Priority`} component={Priority} />
          <Route
            exact
            path={`${match.url}SpecialityMaster`}
            component={SpecialityMaster}
          />
          <Route exact path={`${match.url}CrmRole`} component={CrmRole} />
          <Route
            exact
            path={`${match.url}Notification`}
            component={Notification}
          />
          <Route exact path={`${match.url}Category`} component={Category} />
          <Route exact path={`${match.url}Dnd`} component={Dnd} />
          <Route
            exact
            path={`${match.url}leadStageManage`}
            component={LeadStageManage}
          />
          <Route exact path={`${match.url}SlaMaster`} component={SlaMaster} />
          <Route exact path={`${match.url}Users`} component={Users} />
          <Route
            exact
            path={`${match.url}LeadScoring`}
            component={LeadScoring}
          />
          <Route
            exact
            path={`${match.url}CustomFields`}
            component={CustomFields}
          />
          <Route exact path={`${match.url}Alerts`} component={Alerts} />
          <Route exact path={`${match.url}Template`} component={Template} />
          <Route
            exact
            path={`${match.url}RoleAccessControl`}
            component={RoleAccessControl}
          />
          <Route
            render={() => {
              return "page not found";
            }}
          />
        </Switch>
      </MasterLayout>
    );
  }
}

export default Index;
