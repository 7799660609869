import React, { Component } from "react";
import Add from "./../../../assets/images/add.svg";
import CloseCircle from "./../../../assets/images/CloseCircle.svg";
import AddNote from "./../../../assets/images/AddNote.svg";
import { Input, Radio, Select, Table, Checkbox } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./../../../assets/css/AdminSetting/adminSetting.css";
import Modal from "react-responsive-modal";
import FileUpload from "./../../../assets/images/upload.png";
import Dropzone from "react-dropzone";

class CustomFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createCustomFields: false,
      Single: true,
      Multiple: false,
      fileName: "",
      fileN: [],
    };
  }
  handleCreateCategotyOpen() {
    this.setState({ createCustomFields: true });
  }
  handleCreateCategoryClose() {
    this.setState({ createCustomFields: false });
  }
  start = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({
        selectedRowKeys: [],
        loading: false,
      });
    }, 1000);
  };
  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };
  handleRedioButtonChange(check) {
    if (check === "Single") {
      this.setState({
        Single: true,
        Multiple: false,
      });
    } else {
      this.setState({
        Single: false,
        Multiple: true,
      });
    }
  }
  fileUpload = (e) => {
    var allFiles = [];
    var selectedFiles = e;
    if (selectedFiles) {
      allFiles.push(selectedFiles[0]);

      this.setState({
        fileN: allFiles,
        fileName: allFiles[0].name,
      });
    }
  };
  render() {
    const data = [
      {
        key: "1",
        displayName: "Jr. design engineer",
        sectionName: "HOD",
        fieldType: "Vikash Sharma",
        mandatory: "Vikash Sharma",
        createdBy: "Vikash Sharma",
        updatedBy: "Vikash Sharma",
      },
      {
        key: "2",
        displayName: "Team Lead",
        sectionName: "Asst Manager",
        fieldType: "Vikash Sharma",
        mandatory: "Vikash Sharma",
        createdBy: "Vikash Sharma",
        updatedBy: "Vikash Sharma",
      },
      {
        key: "3",
        displayName: "Supervisor",
        sectionName: "Asst Manager",
        fieldType: "Vikash Sharma",
        mandatory: "Vikash Sharma",
        createdBy: "Vikash Sharma",
        updatedBy: "Vikash Sharma",
      },
    ];
    const { Option } = Select;
    const { loading, selectedRowKeys } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      fixed: "left",
      width: 220,
    };

    return (
      <div className="CustomFieldsMain">
        <div className="TasType">
          <div className="Hierdf">
            <div className="hierleft">
              <h4>
                Custom Fields
                <img
                  src={Add}
                  alt="add"
                  onClick={this.handleCreateCategotyOpen.bind(this)}
                />
              </h4>
            </div>
            <div className="hierright">
              <Input
                placeholder="Search by display name..."
                prefix={<SearchOutlined />}
              />
            </div>
          </div>

          <div className="CustomFieldsTable">
            <Table
              rowSelection={rowSelection}
              columns={[
                {
                  title: "Display Name",
                  dataIndex: "displayName",
                  key: "displayName",
                },
                {
                  title: "Section Name",
                  dataIndex: "sectionName",
                  key: "sectionName",
                },
                {
                  title: "Field Type",
                  key: "fieldType",
                  dataIndex: "fieldType",
                },
                {
                  title: "Mandatory",
                  key: "mandatory",
                  dataIndex: "mandatory",
                },
                {
                  title: "Created by",
                  key: "createdBy",
                  dataIndex: "createdBy",
                },
                {
                  title: "Updated by",
                  key: "updatedBy",
                  dataIndex: "updatedBy",
                },
                {
                  title: "Status",
                  key: "status",
                  dataIndex: "status",
                  render: (row, item) => {
                    return (
                      <div className="Status">
                        <Select defaultValue="Active">
                          <Option value="Active">Active</Option>
                          <Option value="Inactive">Inactive</Option>
                        </Select>
                      </div>
                    );
                  },
                },
                {
                  title: "Actions",
                  key: "actions",
                  dataIndex: "actions",
                  fixed: "right",
                  width: 100,
                  render: (row, item) => {
                    return (
                      <div className="Actionic">
                        <img
                          src={AddNote}
                          alt="Edit"
                          onClick={this.handleCreateCategotyOpen.bind(this)}
                        />
                        <img src={CloseCircle} alt="delete" />
                      </div>
                    );
                  },
                },
              ]}
              dataSource={data}
              pagination={false}
              scroll={{ x: 1300 }}
            />
          </div>
          {/* Create Custom Fields */}
          <Modal
            open={this.state.createCustomFields}
            onClose={this.handleCreateCategoryClose.bind(this)}
            modalId="CreateCustomFieldsModal"
            overlayId="overlay"
          >
            <div className="createCustomFields">
              <div className="hier mb-3">
                <label>Create Custom Fields</label>
              </div>
              <div className="mb-2">
                <Radio
                  type="radio"
                  name="Single"
                  checked={this.state.Single}
                  onChange={this.handleRedioButtonChange.bind(this, "Single")}
                >
                  Single
                </Radio>
                <Radio
                  type="radio"
                  name="Single"
                  checked={this.state.Multiple}
                  onChange={this.handleRedioButtonChange.bind(this, "Multiple")}
                >
                  Multiple
                </Radio>
              </div>
              {this.state.Single && (
                <>
                  <div className="row mb-3">
                    <div className="col-12 col-md-6">
                      <label className="desig">
                        Display Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input type="text" placeholder="Enter Display Name" />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="desig">Include field in section</label>
                      <input type="text" placeholder="Enter Include field" />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-6">
                      <label className="desig">Data type</label>
                      <Select defaultValue="Click to Select">
                        <Option value="manshoes">test</Option>
                      </Select>
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="desig">Default value</label>
                      <Select defaultValue="Click to Select">
                        <Option value="manshoes">test</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <label className="desig">Include field in section</label>
                      <Select defaultValue="Click to Select">
                        <Option value="test">test</Option>
                      </Select>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-12 col-md-12">
                      <Checkbox className="mand">Is mandatory</Checkbox>
                      <Checkbox className="mand">Include in quick search</Checkbox>
                      <Checkbox className="mand">Include in mail merge</Checkbox>
                      <Checkbox className="mand">Show in import</Checkbox>
                    </div>
                  </div>
                </>
              )}
              {this.state.Multiple && (
                <>
                  <div className="editor">
                    <div className="mainfileUpload">
                      <Dropzone onDrop={this.fileUpload.bind(this)}>
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input
                              {...getInputProps()}
                              className="file-upload d-none"
                            />
                            <div className="file-icon">
                              <img src={FileUpload} alt="file-upload" />
                            </div>
                            <span className={"drop"}>
                              Drop your file to upload
                            </span>
                            <button className="fileupload">Choose File</button>
                            <span className="down">Download Sample File</span>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="editorbtn">
              <button
                type="button"
                onClick={this.handleCreateCategoryClose.bind(this)}
              >
                Cancel
              </button>
              <button type="button">CREATE</button>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default CustomFields;
